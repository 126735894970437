// Import Sass modules
@use 'sass:color';
@use 'sass:map';

// Define Material Colors
$material-colors: (
  "red": (
    "50": #ffebee,
    "100": #ffcdd2,
    "200": #ef9a9a,
    "300": #e57373,
    "400": #ef5350,
    "500": #f44336,
    "600": #e53935,
    "700": #d32f2f,
    "800": #c62828,
    "900": #b71c1c,
    "A100": #ff8a80,
    "A200": #ff5252,
    "A400": #ff1744,
    "A700": #d50000,
    "base": #f44336,
  ),
  "pink": (
    "50": #fce4ec,
    "100": #f8bbd0,
    "200": #f48fb1,
    "300": #f06292,
    "400": #ec407a,
    "500": #e91e63,
    "600": #d81b60,
    "700": #c2185b,
    "800": #ad1457,
    "900": #880e4f,
    "A100": #ff80ab,
    "A200": #ff4081,
    "A400": #f50057,
    "A700": #c51162,
    "base": #e91e63,
  ),
  "purple": (
    "50": #f3e5f5,
    "100": #e1bee7,
    "200": #ce93d8,
    "300": #ba68c8,
    "400": #ab47bc,
    "500": #9c27b0,
    "600": #8e24aa,
    "700": #7b1fa2,
    "800": #6a1b9a,
    "900": #4a148c,
    "A100": #ea80fc,
    "A200": #e040fb,
    "A400": #d500f9,
    "A700": #aa00ff,
    "base": #9c27b0,
  ),
  "deep-purple": (
    "50": #ede7f6,
    "100": #d1c4e9,
    "200": #b39ddb,
    "300": #9575cd,
    "400": #7e57c2,
    "500": #673ab7,
    "600": #5e35b1,
    "700": #512da8,
    "800": #4527a0,
    "900": #311b92,
    "A100": #b388ff,
    "A200": #7c4dff,
    "A400": #651fff,
    "A700": #6200ea,
    "base": #673ab7,
  ),
  "indigo": (
    "50": #e8eaf6,
    "100": #c5cae9,
    "200": #9fa8da,
    "300": #7986cb,
    "400": #5c6bc0,
    "500": #3f51b5,
    "600": #3949ab,
    "700": #303f9f,
    "800": #283593,
    "900": #1a237e,
    "A100": #8c9eff,
    "A200": #536dfe,
    "A400": #3d5afe,
    "A700": #304ffe,
    "base": #3f51b5,
  ),
  "blue": (
    "50": #e3f2fd,
    "100": #bbdefb,
    "200": #90caf9,
    "300": #64b5f6,
    "400": #42a5f5,
    "500": #2196f3,
    "600": #1e88e5,
    "700": #1976d2,
    "800": #1565c0,
    "900": #0d47a1,
    "A100": #82b1ff,
    "A200": #448aff,
    "A400": #2979ff,
    "A700": #2962ff,
    "base": #2196f3,
  ),
  "light-blue": (
    "50": #e1f5fe,
    "100": #b3e5fc,
    "200": #81d4fa,
    "300": #4fc3f7,
    "400": #29b6f6,
    "500": #03a9f4,
    "600": #039be5,
    "700": #0288d1,
    "800": #0277bd,
    "900": #01579b,
    "A100": #80d8ff,
    "A200": #40c4ff,
    "A400": #00b0ff,
    "A700": #0091ea,
    "base": #03a9f4,
  ),
  "cyan": (
    "50": #e0f7fa,
    "100": #b2ebf2,
    "200": #80deea,
    "300": #4dd0e1,
    "400": #26c6da,
    "500": #00bcd4,
    "600": #00acc1,
    "700": #0097a7,
    "800": #00838f,
    "900": #006064,
    "A100": #84ffff,
    "A200": #18ffff,
    "A400": #00e5ff,
    "A700": #00b8d4,
    "base": #00bcd4,
  ),
  "teal": (
    "50": #e0f2f1,
    "100": #b2dfdb,
    "200": #80cbc4,
    "300": #4db6ac,
    "400": #26a69a,
    "500": #009688,
    "600": #00897b,
    "700": #00796b,
    "800": #00695c,
    "900": #004d40,
    "A100": #a7ffeb,
    "A200": #64ffda,
    "A400": #1de9b6,
    "A700": #00bfa5,
    "base": #009688,
  ),
  "green": (
    "50": #e8f5e9,
    "100": #c8e6c9,
    "200": #a5d6a7,
    "300": #81c784,
    "400": #66bb6a,
    "500": #4caf50,
    "600": #43a047,
    "700": #388e3c,
    "800": #2e7d32,
    "900": #1b5e20,
    "A100": #b9f6ca,
    "A200": #69f0ae,
    "A400": #00e676,
    "A700": #00c853,
    "base": #4caf50,
  ),
  "light-green": (
    "50": #f1f8e0,
    "100": #dcedc8,
    "200": #c5e1a5,
    "300": #aed581,
    "400": #9ccc65,
    "500": #8bc34a,
    "600": #7cb342,
    "700": #689f38,
    "800": #558b2f,
    "900": #33691e,
    "A100": #ccff90,
    "A200": #b2ff59,
    "A400": #76ff03,
    "A700": #64dd17,
    "base": #8bc34a,
  ),
  "lime": (
    "50": #f9fbe7,
    "100": #f0f4c3,
    "200": #fff59d,
    "300": #dce775,
    "400": #d4e157,
    "500": #cddc39,
    "600": #c0ca33,
    "700": #afb42b,
    "800": #9e9d24,
    "900": #827717,
    "A100": #f4ff81,
    "A200": #eeff41,
    "A400": #c6ff00,
    "A700": #aeea00,
    "base": #cddc39,
  ),
  "yellow": (
    "50": #fffde7,
    "100": #fff9c4,
    "200": #fff59d,
    "300": #fff176,
    "400": #ffee58,
    "500": #fec60a,
    "600": #fdd835,
    "700": #fbc02d,
    "800": #f9a825,
    "900": #f57f17,
    "A100": #ffff8d,
    "A200": #ffff00,
    "A400": #ffea00,
    "A700": #ffd600,
    "base": #fbc02d,
  ),
  "amber": (
    "50": #fff8e1,
    "100": #ffecb3,
    "200": #ffe082,
    "300": #ffd54f,
    "400": #ffca28,
    "500": #ffc107,
    "600": #ffb300,
    "700": #ffa000,
    "800": #ff8f00,
    "900": #ff6f00,
    "A100": #ffe57f,
    "A200": #ffd740,
    "A400": #ffc400,
    "A700": #ffab00,
    "base": #ffc107,
  ),
  "orange": (
    "50": #fff3e0,
    "100": #ffe0b2,
    "200": #ffcc80,
    "300": #ffb74d,
    "400": #ffa726,
    "500": #ff9800,
    "600": #fb8c00,
    "700": #f57c00,
    "800": #ef6c00,
    "900": #e65100,
    "A100": #ffd180,
    "A200": #ffab40,
    "A400": #ff9100,
    "A700": #ff6d00,
    "base": #ff9800,
  ),
  "deep-orange": (
    "50": #fbe9e7,
    "100": #ffccbc,
    "200": #ffab91,
    "300": #ff8a65,
    "400": #ff7043,
    "500": #ff5722,
    "600": #f4511e,
    "700": #e64a19,
    "800": #d84315,
    "900": #bf360c,
    "A100": #ff9e80,
    "A200": #ff6e40,
    "A400": #ff3d00,
    "A700": #dd2c00,
    "base": #ff5722,
  ),
  "brown": (
    "50": #efebe9,
    "100": #d7ccc8,
    "200": #bcaaa4,
    "300": #a1887f,
    "400": #8d6e63,
    "500": #795548,
    "600": #6d4c41,
    "700": #5d4037,
    "800": #4e342e,
    "900": #3e2723,
    "A100": #d7ccc8,
    "A200": #bcaaa4,
    "A400": #8d6e63,
    "A700": #5d4037,
    "base": #795548,
  ),
  "gray": (
    "50": #fafafa,
    "100": #f5f5f5,
    "200": #eeeeee,
    "300": #e0e0e0,
    "400": #bdbdbd,
    "500": #9e9e9e,
    "600": #757575,
    "700": #616161,
    "800": #424242,
    "900": #212121,
    "A100": #f5f5f5,
    "A200": #eeeeee,
    "A400": #bdbdbd,
    "A700": #616161,
    "base": #9e9e9e,
    "rgb-500": "158, 158, 158",
  ),
  "blue-gray": (
    "50": #eceff1,
    "100": #cfd8dc,
    "200": #b0bec5,
    "300": #90a4ae,
    "400": #78909c,
    "500": #607d8b,
    "600": #546e7a,
    "700": #455a64,
    "800": #37474f,
    "900": #263238,
    "A100": #cfd8dc,
    "A200": #b0bec5,
    "A400": #78909c,
    "A700": #455a64,
    "base": #607d8b,
  ),
);

// Define Base Colors
$base-colors: (
  "black": (
    "color": #000000,
    "rgb": "0,0,0",
  ),
  "white": (
    "color": #ffffff,
    "rgb": "255,255,255",
  ),
);

// Function to retrieve material colors
@function material-color($color, $shade: "base") {
  @if not map.has-key($material-colors, $color) {
    @error "Color `#{$color}` is not defined in \$material-colors.";
  }

  @if not map.has-key(map.get($material-colors, $color), $shade) {
    @error "Shade `#{$shade}` is not defined for color `#{$color}`.";
  }

  @return map.get(map.get($material-colors, $color), $shade);
}

// Function to retrieve base colors (e.g., black, white)
@function base-color($color-name, $property: "color") {
  @if not map.has-key($base-colors, $color-name) {
    @error "Color `#{$color-name}` is not defined in \$base-colors.";
  }
  
  @if not map.has-key(map.get($base-colors, $color-name), $property) {
    @error "Property `#{$property}` is not defined for color `#{$color-name}`.";
  }
  
  @return map.get(map.get($base-colors, $color-name), $property);
}

// For backward compatibility with the original variable names
$red-50: material-color("red", "50");
$red-100: material-color("red", "100");
$red-200: material-color("red", "200");
$red-300: material-color("red", "300");
$red-400: material-color("red", "400");
$red-500: material-color("red", "500");
$red-600: material-color("red", "600");
$red-700: material-color("red", "700");
$red-800: material-color("red", "800");
$red-900: material-color("red", "900");
$red-A100: material-color("red", "A100");
$red-A200: material-color("red", "A200");
$red-A400: material-color("red", "A400");
$red-A700: material-color("red", "A700");
$red: material-color("red");

$pink-50: material-color("pink", "50");
$pink-100: material-color("pink", "100");
$pink-200: material-color("pink", "200");
$pink-300: material-color("pink", "300");
$pink-400: material-color("pink", "400");
$pink-500: material-color("pink", "500");
$pink-600: material-color("pink", "600");
$pink-700: material-color("pink", "700");
$pink-800: material-color("pink", "800");
$pink-900: material-color("pink", "900");
$pink-A100: material-color("pink", "A100");
$pink-A200: material-color("pink", "A200");
$pink-A400: material-color("pink", "A400");
$pink-A700: material-color("pink", "A700");
$pink: material-color("pink");

$purple-50: material-color("purple", "50");
$purple-100: material-color("purple", "100");
$purple-200: material-color("purple", "200");
$purple-300: material-color("purple", "300");
$purple-400: material-color("purple", "400");
$purple-500: material-color("purple", "500");
$purple-600: material-color("purple", "600");
$purple-700: material-color("purple", "700");
$purple-800: material-color("purple", "800");
$purple-900: material-color("purple", "900");
$purple-A100: material-color("purple", "A100");
$purple-A200: material-color("purple", "A200");
$purple-A400: material-color("purple", "A400");
$purple-A700: material-color("purple", "A700");
$purple: material-color("purple");

$deep-purple-50: material-color("deep-purple", "50");
$deep-purple-100: material-color("deep-purple", "100");
$deep-purple-200: material-color("deep-purple", "200");
$deep-purple-300: material-color("deep-purple", "300");
$deep-purple-400: material-color("deep-purple", "400");
$deep-purple-500: material-color("deep-purple", "500");
$deep-purple-600: material-color("deep-purple", "600");
$deep-purple-700: material-color("deep-purple", "700");
$deep-purple-800: material-color("deep-purple", "800");
$deep-purple-900: material-color("deep-purple", "900");
$deep-purple-A100: material-color("deep-purple", "A100");
$deep-purple-A200: material-color("deep-purple", "A200");
$deep-purple-A400: material-color("deep-purple", "A400");
$deep-purple-A700: material-color("deep-purple", "A700");
$deep-purple: material-color("deep-purple");

$indigo-50: material-color("indigo", "50");
$indigo-100: material-color("indigo", "100");
$indigo-200: material-color("indigo", "200");
$indigo-300: material-color("indigo", "300");
$indigo-400: material-color("indigo", "400");
$indigo-500: material-color("indigo", "500");
$indigo-600: material-color("indigo", "600");
$indigo-700: material-color("indigo", "700");
$indigo-800: material-color("indigo", "800");
$indigo-900: material-color("indigo", "900");
$indigo-A100: material-color("indigo", "A100");
$indigo-A200: material-color("indigo", "A200");
$indigo-A400: material-color("indigo", "A400");
$indigo-A700: material-color("indigo", "A700");
$indigo: material-color("indigo");

$blue-50: material-color("blue", "50");
$blue-100: material-color("blue", "100");
$blue-200: material-color("blue", "200");
$blue-300: material-color("blue", "300");
$blue-400: material-color("blue", "400");
$blue-500: material-color("blue", "500");
$blue-600: material-color("blue", "600");
$blue-700: material-color("blue", "700");
$blue-800: material-color("blue", "800");
$blue-900: material-color("blue", "900");
$blue-A100: material-color("blue", "A100");
$blue-A200: material-color("blue", "A200");
$blue-A400: material-color("blue", "A400");
$blue-A700: material-color("blue", "A700");
$blue: material-color("blue");

$light-blue-50: material-color("light-blue", "50");
$light-blue-100: material-color("light-blue", "100");
$light-blue-200: material-color("light-blue", "200");
$light-blue-300: material-color("light-blue", "300");
$light-blue-400: material-color("light-blue", "400");
$light-blue-500: material-color("light-blue", "500");
$light-blue-600: material-color("light-blue", "600");
$light-blue-700: material-color("light-blue", "700");
$light-blue-800: material-color("light-blue", "800");
$light-blue-900: material-color("light-blue", "900");
$light-blue-A100: material-color("light-blue", "A100");
$light-blue-A200: material-color("light-blue", "A200");
$light-blue-A400: material-color("light-blue", "A400");
$light-blue-A700: material-color("light-blue", "A700");
$light-blue: material-color("light-blue");

$cyan-50: material-color("cyan", "50");
$cyan-100: material-color("cyan", "100");
$cyan-200: material-color("cyan", "200");
$cyan-300: material-color("cyan", "300");
$cyan-400: material-color("cyan", "400");
$cyan-500: material-color("cyan", "500");
$cyan-600: material-color("cyan", "600");
$cyan-700: material-color("cyan", "700");
$cyan-800: material-color("cyan", "800");
$cyan-900: material-color("cyan", "900");
$cyan-A100: material-color("cyan", "A100");
$cyan-A200: material-color("cyan", "A200");
$cyan-A400: material-color("cyan", "A400");
$cyan-A700: material-color("cyan", "A700");
$cyan: material-color("cyan");

$teal-50: material-color("teal", "50");
$teal-100: material-color("teal", "100");
$teal-200: material-color("teal", "200");
$teal-300: material-color("teal", "300");
$teal-400: material-color("teal", "400");
$teal-500: material-color("teal", "500");
$teal-600: material-color("teal", "600");
$teal-700: material-color("teal", "700");
$teal-800: material-color("teal", "800");
$teal-900: material-color("teal", "900");
$teal-A100: material-color("teal", "A100");
$teal-A200: material-color("teal", "A200");
$teal-A400: material-color("teal", "A400");
$teal-A700: material-color("teal", "A700");
$teal: material-color("teal");

$green-50: material-color("green", "50");
$green-100: material-color("green", "100");
$green-200: material-color("green", "200");
$green-300: material-color("green", "300");
$green-400: material-color("green", "400");
$green-500: material-color("green", "500");
$green-600: material-color("green", "600");
$green-700: material-color("green", "700");
$green-800: material-color("green", "800");
$green-900: material-color("green", "900");
$green-A100: material-color("green", "A100");
$green-A200: material-color("green", "A200");
$green-A400: material-color("green", "A400");
$green-A700: material-color("green", "A700");
$green: material-color("green");

$light-green-50: material-color("light-green", "50");
$light-green-100: material-color("light-green", "100");
$light-green-200: material-color("light-green", "200");
$light-green-300: material-color("light-green", "300");
$light-green-400: material-color("light-green", "400");
$light-green-500: material-color("light-green", "500");
$light-green-600: material-color("light-green", "600");
$light-green-700: material-color("light-green", "700");
$light-green-800: material-color("light-green", "800");
$light-green-900: material-color("light-green", "900");
$light-green-A100: material-color("light-green", "A100");
$light-green-A200: material-color("light-green", "A200");
$light-green-A400: material-color("light-green", "A400");
$light-green-A700: material-color("light-green", "A700");
$light-green: material-color("light-green");

$lime-50: material-color("lime", "50");
$lime-100: material-color("lime", "100");
$lime-200: material-color("lime", "200");
$lime-300: material-color("lime", "300");
$lime-400: material-color("lime", "400");
$lime-500: material-color("lime", "500");
$lime-600: material-color("lime", "600");
$lime-700: material-color("lime", "700");
$lime-800: material-color("lime", "800");
$lime-900: material-color("lime", "900");
$lime-A100: material-color("lime", "A100");
$lime-A200: material-color("lime", "A200");
$lime-A400: material-color("lime", "A400");
$lime-A700: material-color("lime", "A700");
$lime: material-color("lime");

$yellow: material-color("yellow", "700");
$yellow-50: material-color("yellow", "50");
$yellow-100: material-color("yellow", "100");
$yellow-200: material-color("yellow", "200");
$yellow-300: material-color("yellow", "300");
$yellow-400: material-color("yellow", "400");
$yellow-500: material-color("yellow", "500");
$yellow-600: material-color("yellow", "600");
$yellow-700: material-color("yellow", "700");
$yellow-800: material-color("yellow", "800");
$yellow-900: material-color("yellow", "900");
$yellow-A100: material-color("yellow", "A100");
$yellow-A200: material-color("yellow", "A200");
$yellow-A400: material-color("yellow", "A400");
$yellow-A700: material-color("yellow", "A700");

$amber-50: material-color("amber", "50");
$amber-100: material-color("amber", "100");
$amber-200: material-color("amber", "200");
$amber-300: material-color("amber", "300");
$amber-400: material-color("amber", "400");
$amber-500: material-color("amber", "500");
$amber-600: material-color("amber", "600");
$amber-700: material-color("amber", "700");
$amber-800: material-color("amber", "800");
$amber-900: material-color("amber", "900");
$amber-A100: material-color("amber", "A100");
$amber-A200: material-color("amber", "A200");
$amber-A400: material-color("amber", "A400");
$amber-A700: material-color("amber", "A700");
$amber: material-color("amber");

$orange-50: material-color("orange", "50");
$orange-100: material-color("orange", "100");
$orange-200: material-color("orange", "200");
$orange-300: material-color("orange", "300");
$orange-400: material-color("orange", "400");
$orange-500: material-color("orange", "500");
$orange-600: material-color("orange", "600");
$orange-700: material-color("orange", "700");
$orange-800: material-color("orange", "800");
$orange-900: material-color("orange", "900");
$orange-A100: material-color("orange", "A100");
$orange-A200: material-color("orange", "A200");
$orange-A400: material-color("orange", "A400");
$orange-A700: material-color("orange", "A700");
$orange: material-color("orange");

$deep-orange-50: material-color("deep-orange", "50");
$deep-orange-100: material-color("deep-orange", "100");
$deep-orange-200: material-color("deep-orange", "200");
$deep-orange-300: material-color("deep-orange", "300");
$deep-orange-400: material-color("deep-orange", "400");
$deep-orange-500: material-color("deep-orange", "500");
$deep-orange-600: material-color("deep-orange", "600");
$deep-orange-700: material-color("deep-orange", "700");
$deep-orange-800: material-color("deep-orange", "800");
$deep-orange-900: material-color("deep-orange", "900");
$deep-orange-A100: material-color("deep-orange", "A100");
$deep-orange-A200: material-color("deep-orange", "A200");
$deep-orange-A400: material-color("deep-orange", "A400");
$deep-orange-A700: material-color("deep-orange", "A700");
$deep-orange: material-color("deep-orange");

$brown-50: material-color("brown", "50");
$brown-100: material-color("brown", "100");
$brown-200: material-color("brown", "200");
$brown-300: material-color("brown", "300");
$brown-400: material-color("brown", "400");
$brown-500: material-color("brown", "500");
$brown-600: material-color("brown", "600");
$brown-700: material-color("brown", "700");
$brown-800: material-color("brown", "800");
$brown-900: material-color("brown", "900");
$brown-A100: material-color("brown", "A100");
$brown-A200: material-color("brown", "A200");
$brown-A400: material-color("brown", "A400");
$brown-A700: material-color("brown", "A700");
$brown: material-color("brown");

$gray-50: material-color("gray", "50");
$gray-100: material-color("gray", "100");
$gray-200: material-color("gray", "200");
$gray-300: material-color("gray", "300");
$gray-400: material-color("gray", "400");
$gray-500: material-color("gray", "500");
$gray-600: material-color("gray", "600");
$gray-700: material-color("gray", "700");
$gray-800: material-color("gray", "800");
$gray-900: material-color("gray", "900");
$gray-A100: material-color("gray", "A100");
$gray-A200: material-color("gray", "A200");
$gray-A400: material-color("gray", "A400");
$gray-A700: material-color("gray", "A700");
$gray: material-color("gray");
$gray-dark: material-color("gray", "600");

$blue-gray-50: material-color("blue-gray", "50");
$blue-gray-100: material-color("blue-gray", "100");
$blue-gray-200: material-color("blue-gray", "200");
$blue-gray-300: material-color("blue-gray", "300");
$blue-gray-400: material-color("blue-gray", "400");
$blue-gray-500: material-color("blue-gray", "500");
$blue-gray-600: material-color("blue-gray", "600");
$blue-gray-700: material-color("blue-gray", "700");
$blue-gray-800: material-color("blue-gray", "800");
$blue-gray-900: material-color("blue-gray", "900");
$blue-gray-A100: material-color("blue-gray", "A100");
$blue-gray-A200: material-color("blue-gray", "A200");
$blue-gray-A400: material-color("blue-gray", "A400");
$blue-gray-A700: material-color("blue-gray", "A700");
$blue-gray: material-color("blue-gray");

$black: base-color("black", "color");
$white: base-color("white", "color");
$rgb-black: base-color("black", "rgb");
$rgb-white: base-color("white", "rgb");
$rgb-gray-500: map.get(map.get($material-colors, "gray"), "rgb-500");
