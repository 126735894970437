// Import Sass modules
@use 'sass:color';
@use 'sass:map';

// Variables
$fixed-plugin: (
  "position": (
    "top": 180px,
    "mobile-top": 80px,
    "width": 64px,
    "dropdown-width": (
      "default": 290px,
      "mobile": 250px,
    ),
  ),
  "colors": (
    "background": rgba(0, 0, 0, 0.3),
    "text": (
      "white": #ffffff,
      "active": #00bbff,
      "dark": #333,
      "gray": #777777,
      "dark-gray": #3c4858,
    ),
    "border": (
      "light": rgba(0, 0, 0, 0.15),
      "dropdown": rgba(0, 0, 0, 0.2),
      "divider": #ddd,
      "badge": (
        "default": #ffffff,
        "active": #00bbff,
        "hover": rgba(0, 187, 255, 0.53),
      ),
    ),
    "badges": (
      "blue": #00bcd4,
      "green": #4caf50,
      "orange": #ff9800,
      "purple": #9c27b0,
      "red": #f44336,
      "rose": #e91e63,
      "black": #000,
      "white": rgba(200, 200, 200, 0.2),
    ),
  ),
  "sizes": (
    "badge": (
      "size": 23px,
      "border": 3px,
    ),
    "border-radius": (
      "plugin": 8px,
      "dropdown": 10px,
      "item": 2px,
    ),
    "font": (
      "default": 14px,
      "small": 13px,
      "smaller": 12px,
    ),
    "spacing": (
      "padding": (
        "default": 10px,
        "dropdown": 0 10px,
        "list": 5px 2px,
        "item": 10px 20px,
      ),
      "margin": (
        "default": 10px,
        "item": 0 5px,
        "badge": 0 5px 0 0,
      ),
    ),
  ),
  "transitions": (
    "badge": 0.34s,
    "menu-item": 150ms linear,
    "transform": (
      "default": translateY(-15%),
      "show": translateY(-13%),
    ),
  ),
);

// Mixins
@mixin transform($value) {
  -webkit-transform: $value;
  -moz-transform: $value;
  -o-transform: $value;
  -ms-transform: $value;
  transform: $value;
}

@mixin transition($value) {
  -webkit-transition: $value;
  -moz-transition: $value;
  transition: $value;
}

@mixin badge-color($color) {
  background-color: $color;
}

// Base styles
.fixed-plugin {
  position: fixed;
  top: map.get($fixed-plugin, "position", "top");
  right: 0;
  width: map.get($fixed-plugin, "position", "width");
  background: map.get($fixed-plugin, "colors", "background");
  z-index: 1031;
  border-radius: map.get($fixed-plugin, "sizes", "border-radius", "plugin") 0 0
    map.get($fixed-plugin, "sizes", "border-radius", "plugin");
  text-align: center;

  // RTL support
  &.fixed-plugin-rtl {
    right: auto;
    left: 0;
    border-radius: 0 map.get($fixed-plugin, "sizes", "border-radius", "plugin")
      map.get($fixed-plugin, "sizes", "border-radius", "plugin") 0;
    direction: ltr;

    .dropdown.show .dropdown-menu {
      right: auto;
      left: 80px;

      &:before {
        border-left: none;
        border-right: 16px solid
          map.get($fixed-plugin, "colors", "border", "dropdown");
        left: -17px;
        right: auto;
      }

      &:after {
        border-left: none;
        border-right: 16px solid
          map.get($fixed-plugin, "colors", "text", "white");
        left: -16px;
        right: auto;
      }
    }
  }

  // Icons
  .fa-cog {
    color: map.get($fixed-plugin, "colors", "text", "white");
    padding: map.get($fixed-plugin, "sizes", "spacing", "padding", "default");
    border-radius: 0 0 6px 6px;
    width: auto;
  }

  .fa-circle-thin {
    color: map.get($fixed-plugin, "colors", "text", "white");

    .active & {
      color: map.get($fixed-plugin, "colors", "text", "active");
    }
  }

  // Dropdown menu
  .dropdown-menu {
    text-align: center;
    right: 80px;
    left: auto;
    width: map.get($fixed-plugin, "position", "dropdown-width", "default");
    border-radius: map.get($fixed-plugin, "sizes", "border-radius", "dropdown");
    padding: map.get($fixed-plugin, "sizes", "spacing", "padding", "dropdown");
    background-color: map.get($fixed-plugin, "colors", "text", "white");
    border: 1px solid map.get($fixed-plugin, "colors", "border", "light");
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);

    &:before,
    &:after {
      content: "";
      display: inline-block;
      position: absolute;
      top: 84px;
      @include transform(translateY(-50%));
    }

    &:before {
      border-bottom: 16px solid transparent;
      border-left: 16px solid
        map.get($fixed-plugin, "colors", "border", "dropdown");
      border-top: 16px solid transparent;
      right: -17px;
    }

    &:after {
      border-bottom: 16px solid transparent;
      border-left: 16px solid map.get($fixed-plugin, "colors", "text", "white");
      border-top: 16px solid transparent;
      right: -16px;
    }

    li {
      display: block;
      padding: map.get($fixed-plugin, "sizes", "spacing", "padding", "list");
      width: 25%;
      float: left;

      > a {
        font-size: map.get($fixed-plugin, "sizes", "font", "small");
        padding: map.get($fixed-plugin, "sizes", "spacing", "padding", "item");
        margin: map.get($fixed-plugin, "sizes", "spacing", "margin", "item");
        border-radius: map.get($fixed-plugin, "sizes", "border-radius", "item");
        @include transition(map.get($fixed-plugin, "transitions", "menu-item"));

        &.img-holder {
          font-size: map.get($fixed-plugin, "sizes", "font", "default");
          text-align: center;
          border-radius: map.get(
            $fixed-plugin,
            "sizes",
            "border-radius",
            "dropdown"
          );
          background-color: map.get($fixed-plugin, "colors", "text", "white");
          border: 3px solid map.get($fixed-plugin, "colors", "text", "white");
          padding: 0;
          opacity: 1;
          cursor: pointer;
          max-height: 100px;
          overflow: hidden;

          &:hover,
          &:focus {
            border-color: map.get(
              $fixed-plugin,
              "colors",
              "border",
              "badge",
              "hover"
            );
          }
        }
      }
    }
  }

  // Badges
  .badge {
    border: map.get($fixed-plugin, "sizes", "badge", "border") solid
      map.get($fixed-plugin, "colors", "border", "badge", "default");
    border-radius: 50%;
    cursor: pointer;
    display: inline-block;
    height: map.get($fixed-plugin, "sizes", "badge", "size");
    margin-right: map.get($fixed-plugin, "sizes", "spacing", "margin", "badge");
    position: relative;
    width: map.get($fixed-plugin, "sizes", "badge", "size");
    @include transition(all map.get($fixed-plugin, "transitions", "badge"));

    &.active,
    &:hover {
      border-color: map.get(
        $fixed-plugin,
        "colors",
        "border",
        "badge",
        "active"
      );
    }

    // Badge colors
    @each $name, $color in map.get($fixed-plugin, "colors", "badges") {
      &-#{$name} {
        @include badge-color($color);
      }
    }
  }

  // Special sections
  li {
    &.adjustments-line,
    &.header-title,
    &.button-container {
      width: 100%;
      height: 50px;
      min-height: inherit;
      position: relative;
    }

    &.header-title {
      height: 30px;
      line-height: 25px;
      font-size: map.get($fixed-plugin, "sizes", "font", "smaller");
      font-weight: 600;
      text-align: center;
      text-transform: uppercase;
    }
  }

  // Adjustments line
  .adjustments-line {
    p {
      float: left;
      display: inline-block;
      margin-bottom: 0;
      font-size: 1em;
      color: map.get($fixed-plugin, "colors", "text", "dark-gray");
      margin-top: 4px;

      &.switch-label + span {
        position: absolute;
        right: 0;
        top: -4px;
      }
    }

    .togglebutton {
      float: right;

      .toggle {
        margin-right: 0;
      }
    }
  }

  // Social buttons
  .btn-social {
    width: 48%;
    float: left;
    font-weight: 600;

    i {
      margin-right: 5px;
    }

    &:first-child {
      margin-right: 2%;
    }
  }

  // Dropdown animations
  .dropdown {
    .dropdown-menu {
      @include transform(
        map.get($fixed-plugin, "transitions", "transform", "default")
      );
      top: 8px;
      opacity: 0;
      transform-origin: 0 0;
      display: none;
    }

    &.show .dropdown-menu {
      opacity: 1;
      @include transform(
        map.get($fixed-plugin, "transitions", "transform", "show")
      );
      transform-origin: 0 0;
      display: block;
    }
  }

  // Clearfix
  .clearfix {
    &::before,
    &::after {
      display: table;
      content: " ";
    }
    &::after {
      clear: both;
    }
  }
}

// Media queries
@media (max-width: 991px) {
  .fixed-plugin {
    top: map.get($fixed-plugin, "position", "mobile-top");

    .dropdown-menu {
      right: 81px;
      width: map.get($fixed-plugin, "position", "dropdown-width", "mobile");

      &:after,
      &:before {
        top: 96px;
      }

      > li > a.img-holder {
        max-height: 75px;
      }
    }

    li.header-title {
      height: 40px;
    }

    .adjustments-line .switch {
      float: right;
      margin: 0;
    }

    .dropdown .dropdown-menu {
      top: 10px;
    }
  }
}
