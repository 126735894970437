// Import variables and utility functions
@use "sass:color";
@use "sass:math";
@use "../variables" as var; // Updated relative path

// Mixin for scrollbar thumb styling
@mixin scrollbar-thumb($bg-color, $hover-bg, $size, $hover-size) {
  background-color: $bg-color;
  border-radius: math.div($size, 2);
  transition: background-color 0.2s linear, size 0.2s ease-in-out;

  &:hover,
  &:focus {
    background-color: $hover-bg;
    size: $hover-size;
  }
}

// Mixin for scrollbar rails
@mixin scrollbar-rail($axis, $size, $position) {
  display: none;
  opacity: 0;
  transition: background-color 0.2s linear, opacity 0.2s linear;
  position: absolute;

  @if $axis == x {
    height: $size;
    bottom: $position;
  } @else if $axis == y {
    width: $size;
    right: $position;
  }
}

// Container style for modern browsers
.ps {
  overflow: hidden !important;
  overflow-anchor: none;
  -ms-overflow-style: none;
  touch-action: auto;
  -ms-touch-action: auto;

  &.ps--active-x {
    .ps__rail-x {
      display: block;
    }
  }

  &.ps--active-y {
    .ps__rail-y {
      display: block;
    }
  }

  &:hover,
  &--focus,
  &--scrolling-x,
  &--scrolling-y {
    .ps__rail-x,
    .ps__rail-y {
      opacity: 0.6;
    }
  }
}

// Scrollbar rail styles
.ps__rail-x {
  @include scrollbar-rail(x, 15px, 0px);
}

.ps__rail-y {
  @include scrollbar-rail(y, 15px, 0);
}

.ps__rail-x:hover,
.ps__rail-y:hover,
.ps__rail-x:focus,
.ps__rail-y:focus {
  background-color: #eee;
  opacity: 0.9;
}

// Scrollbar thumb styles
.ps__thumb-x {
  @include scrollbar-thumb(#aaa, #999, 6px, 11px);
}

.ps__thumb-y {
  @include scrollbar-thumb(#aaa, #999, 6px, 11px);
}

.ps__rail-x {
  bottom: 2px; // Keep consistent
  @include scrollbar-rail(x, 15px, 2px);
}

.ps__rail-y {
  right: 2px; // Keep consistent
  @include scrollbar-rail(y, 15px, 2px);
}

// Scrollbar Thumb hover
.ps__rail-x:hover > .ps__thumb-x,
.ps__rail-x:focus > .ps__thumb-x {
  background-color: #999;
  height: 11px;
}

.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y {
  background-color: #999;
  width: 11px;
}

// Legacy MS Support
@supports (-ms-overflow-style: none) {
  .ps {
    overflow: auto !important;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ps {
    overflow: auto !important;
  }
}
