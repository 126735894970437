.ReactTable {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: transparent;

  * {
    box-sizing: border-box;
  }

  .actions-right {
    text-align: right;
  }

  .actions-left {
    text-align: left;
  }

  .actions-center {
    text-align: center;
  }

  .rt-table {
    flex: auto 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    border-collapse: collapse;
    overflow: auto;
  }

  .rt-thead {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    user-select: none;

    &.-headerGroups {
      background: rgba(0, 0, 0, 0.03);
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    }

    &.-filters {
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);

      input,
      select {
        border: 1px solid rgba(0, 0, 0, 0.1);
        background: #fff;
        padding: 5px 7px;
        font-size: inherit;
        border-radius: 3px;
        font-weight: normal;
        outline: none;
      }
    }

    .rt-tr {
      text-align: center;
    }

    .rt-th,
    .rt-td {
      font-size: 1.063rem;
      text-align: left;
      font-weight: 300;
      line-height: 2.5em !important;
      padding: 5px 5px;
      line-height: normal;
      position: relative;
      transition: box-shadow 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
      box-shadow: inset 0 0 0 0 transparent;

      &:last-child {
        border-right: 0;
        text-align: right;
        margin-top: 0;
        pointer-events: none;
      }

      &.-cursor-pointer {
        cursor: pointer;
      }
    }

    .rt-th.-cursor-pointer > div:first-of-type {
      &:after {
        opacity: 0.4;
        content: "\F0DC";
        font-weight: bold;
        position: relative;
        display: inline-block;
        bottom: 0px;
        right: -7px;
        font-family: "Font awesome\\5 Free";
      }

      &.-sort-desc &:after {
        content: "\F0DD";
        top: -3px;
        opacity: 0.8;
      }

      &.-sort-asc &:after {
        content: "\F0DE";
        top: 3px;
        opacity: 0.8;
      }
    }

    .rt-resizable-header {
      overflow: visible;

      &:last-child {
        overflow: hidden;
      }

      &-content {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .rt-header-pivot {
      border-right-color: #f7f7f7;

      &:after,
      &:before {
        left: 100%;
        top: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
      }

      &:after {
        border-color: transparent;
        border-left-color: #fff;
        border-width: 8px;
        margin-top: -8px;
      }

      &:before {
        border-color: transparent;
        border-left-color: #f7f7f7;
        border-width: 10px;
        margin-top: -10px;
      }
    }
  }

  .rt-tbody {
    flex: 99999 1 auto;
    display: flex;
    flex-direction: column;
    overflow: auto;

    .rt-tr-group {
      border-bottom: solid 1px rgba(0, 0, 0, 0.05);

      &:last-child {
        border-bottom: 0;
      }
    }

    .rt-td:last-child {
      border-right: 0;
    }

    .rt-expandable {
      cursor: pointer;
    }
  }

  .rt-tr-group {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  .rt-tr {
    flex: 1 0 auto;
    display: inline-flex;
  }

  .rt-th,
  .rt-td {
    flex: 1 0 0;
    text-overflow: ellipsis;
    padding: 7px 5px;
    overflow: hidden;
    transition: 0.3s ease;
    transition-property: width, min-width, padding, opacity;
    margin: auto;

    &.-hidden {
      width: 0 !important;
      min-width: 0 !important;
      padding: 0 !important;
      border: 0 !important;
      opacity: 0 !important;
    }
  }

  .rt-expander {
    display: inline-block;
    position: relative;
    margin: 0 10px;
    color: transparent;

    &:after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(-90deg);
      border-left: 5.04px solid transparent;
      border-right: 5.04px solid transparent;
      border-top: 7px solid rgba(0, 0, 0, 0.8);
      transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
      cursor: pointer;
    }

    &.-open:after {
      transform: translate(-50%, -50%) rotate(0);
    }
  }

  .rt-resizer {
    display: inline-block;
    position: absolute;
    width: 36px;
    top: 0;
    bottom: 0;
    right: -18px;
    cursor: col-resize;
    z-index: 10;
  }

  .rt-tfoot {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);

    .rt-td {
      border-right: 1px solid rgba(0, 0, 0, 0.05);

      &:last-child {
        border-right: 0;
      }
    }
  }

  &.-striped .rt-tr.-odd {
    background: rgba(0, 0, 0, 0.03);
  }

  &.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
    background: rgba(0, 0, 0, 0.05);
  }

  .-pagination {
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
    padding: 3px;

    input,
    select {
      border: 1px solid rgba(0, 0, 0, 0.1);
      background: #fff;
      padding: 5px 7px;
      font-size: inherit;
      border-radius: 3px;
      font-weight: normal;
      outline: none;
    }

    .-btn {
      appearance: none;
      width: 100%;
      height: 100%;
      border: 0;
      border-radius: 3px;
      padding: 6px;
      font-size: 1em;
      color: rgba(0, 0, 0, 0.6);
      background: rgba(0, 0, 0, 0.1);
      transition: all 0.1s ease;
      cursor: pointer;
      outline: none;

      &[disabled] {
        opacity: 0.5;
        cursor: not-allowed;
      }

      &:not([disabled]):hover {
        background: rgba(0, 0, 0, 0.3);
        color: white;
      }
    }

    .-previous,
    .-next {
      flex: 1;
      text-align: center;
    }

    .-center {
      flex: 1.5;
      text-align: center;
      margin-bottom: 0;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-around;
    }

    .-pageInfo {
      display: inline-block;
      margin: 3px 10px;
    }

    .-pageJump {
      display: inline-block;

      input {
        text-align: center;
        border: 0;
        padding: 7px 0;
        font-size: 14px;
        line-height: 1.428571429;
        width: 70px;
        background-image: linear-gradient(#9c27b0, #9c27b0),
          linear-gradient(#d2d2d2, #d2d2d2);
        background-size: 0 2px, 100% 1px;
        background-repeat: no-repeat;
        background-position: center bottom, center calc(100% - 1px);
        background-color: transparent;
        transition: background 0s ease-out;

        &:focus {
          outline: none;
          background-size: 100% 2px, 100% 1px;
          box-shadow: none;
          transition-duration: 0.3s;
        }
      }
    }

    .-pageSizeOptions {
      margin: 3px 10px;
    }
  }

  .rt-noData {
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: rgba(255, 255, 255, 0.8);
    transition: all 0.3s ease;
    z-index: 1;
    pointer-events: none;
    padding: 20px;
    color: rgba(0, 0, 0, 0.5);
  }

  .-loading {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.8);
    transition: all 0.3s ease;
    z-index: -1;
    opacity: 0;
    pointer-events: none;

    > div {
      position: absolute;
      display: block;
      text-align: center;
      width: 100%;
      top: 50%;
      left: 0;
      font-size: 15px;
      color: rgba(0, 0, 0, 0.6);
      transform: translateY(-52%);
      transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    }

    &.-active {
      opacity: 1;
      z-index: 2;
      pointer-events: all;

      > div {
        transform: translateY(50%);
      }
    }
  }

  .rt-resizing .rt-th,
  .rt-resizing .rt-td {
    transition: none !important;
    cursor: col-resize;
    user-select: none;
  }

  .rt-thead .rt-th,
  .rt-thead .rt-td {
    input {
      text-align: left;
    }
  }
}
