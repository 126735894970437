// src/assets/scss/material-dashboard-pro-react/plugins/_plugin-react-datetime.scss
@use "../variables" as *;
@use "sass:color";
@use "sass:map";
@use "../colors" as colors;
@use "../shadows" as shadows;

// Variables
$datetime: (
  "colors": (
    "primary": $brand-primary,
    "white": $white-color,
    "gray": (
      "light": #eeeeee,
      "disabled": #999999,
      "icon": colors.$gray-A200,
      // Updated to use namespaced variable
        "old-new": colors.$gray-A400,
      // Updated to use namespaced variable
    ),
    "border": #e3e3e3,
  ),
  "transitions": (
    "picker": 150ms linear,
    "counter": 60ms ease-in,
  ),
  "sizes": (
    "input-height": 36px,
    "day": 30px,
    "month-year": 56px,
    "picker-width": 260px,
  ),
);

// Mixins
@mixin user-select-none {
  user-select: none;
}

@mixin transition($props...) {
  transition: $props;
}

@mixin form-control-background($size-gradient1: 0 2px) {
  background-image: linear-gradient(#9c27b0, #9c27b0),
    linear-gradient(#d2d2d2, #d2d2d2);
  background-size: $size-gradient1, 100% 1px;
  background-repeat: no-repeat;
  background-position: center bottom, center calc(100% - 1px);
  background-color: transparent;
}

// Base styles
.rdt {
  position: relative;

  .rdtPicker {
    @include transition(all map.get($datetime, "transitions", "picker"));
    margin-top: -20px;
    visibility: hidden;
    display: block;
    opacity: 0;
  }

  &.rdtOpen .rdtPicker {
    opacity: 1;
    visibility: visible;
    margin-top: 0;
  }

  input.form-control {
    border: 0;
    @include form-control-background;
    transition: background 0s ease-out;
    float: none;
    box-shadow: none;
    border-radius: 0;
    font-weight: 400;
    height: map.get($datetime, "sizes", "input-height");
    padding: 7px 0;
    font-size: 12px;
    line-height: 1.428571429;
    display: block;
    width: 100%;
    color: #555;

    &:focus {
      outline: none;
      @include form-control-background(100% 2px);
      box-shadow: none;
      transition-duration: 0.3s;
    }
  }
}

// Picker styles
.rdtPicker {
  display: block;
  position: absolute;
  width: map.get($datetime, "sizes", "picker-width");
  padding: 4px;
  margin-top: 1px;
  z-index: 99999 !important;
  background: map.get($datetime, "colors", "white");
  border-radius: 0.125rem;
  box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.2);
  background-clip: padding-box;
  min-width: 160px;
  top: 40px;

  // Arrow styles
  &:before,
  &:after {
    display: inline-block;
    position: absolute;
    width: 0;
    height: 0;
    vertical-align: middle;
    content: "";
    top: -5px;
    left: 10px;
    right: auto;
    border-right: 0.4em solid transparent;
    border-left: 0.4em solid transparent;
  }

  &:before {
    border-bottom: 0.4em solid map.get($datetime, "colors", "white");
  }

  &:after {
    border-bottom: 0.4em solid map.get($datetime, "colors", "white");
  }

  // Table styles
  table {
    width: 100%;
    margin: 0;
    border-color: map.get($datetime, "colors", "white") !important;
    border-collapse: collapse;
  }

  td,
  th {
    text-align: center;
    padding: 1px;
  }

  td {
    cursor: pointer;
  }
}

// Day styles
.rdtDay {
  height: map.get($datetime, "sizes", "day");
  line-height: map.get($datetime, "sizes", "day") + 3px;
  width: map.get($datetime, "sizes", "day");
  text-align: center;
  padding: 0;
  border-radius: 50%;

  &.rdtToday.rdtActive,
  &.rdtActive,
  &.rdtActive:hover {
    background-color: map.get($datetime, "colors", "primary") !important;
    color: map.get($datetime, "colors", "white");
    @include shadows.shadow-big-color(map.get($datetime, "colors", "primary"));
  }
}

// Days table styles
.rdtDays tr {
  .dow {
    border-bottom: 1px solid map.get($datetime, "colors", "border");
    text-align: center;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: inherit;
    padding-bottom: 5px;
    padding-top: 10px;
  }

  .rdtOld,
  .rdtNew {
    color: map.get($datetime, "colors", "gray", "old-new");
  }
}

// Hover states
.rdtPicker {
  td.rdtDay,
  td.rdtHour,
  td.rdtMinute,
  td.rdtSecond,
  .rdtTimeToggle {
    &:hover {
      background: map.get($datetime, "colors", "gray", "light");
      cursor: pointer;
    }
  }
}

// Counter styles
.rdtCounters {
  display: inline-block;

  > div {
    float: left;
    width: 40px;
    font-weight: inherit;
    margin: 3px;
    border-radius: 50%;
  }

  .rdtCounterSeparator {
    width: 0;
    border: 1px solid transparent;
  }

  .rdtCounter:last-child .rdtCount {
    color: map.get($datetime, "colors", "primary");
    border-radius: 50%;
    border: 1px solid map.get($datetime, "colors", "primary");
  }
}

// Month/Year view styles
.rdtMonths,
.rdtYears {
  padding-bottom: 10px;

  .rdtMonth,
  .rdtYear {
    display: inline-block;
    width: map.get($datetime, "sizes", "month-year");
    height: map.get($datetime, "sizes", "month-year");
    line-height: map.get($datetime, "sizes", "month-year");
    margin: 3px;
    cursor: pointer;
    border-radius: 50%;
    text-align: center;

    &.rdtActive {
      background-color: map.get($datetime, "colors", "primary") !important;
      color: map.get($datetime, "colors", "white");
    }
  }
}
