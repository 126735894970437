// src/assets/scss/material-dashboard-pro-react/plugins/_plugin-react-jvectormap.scss

// Import Sass modules
@use 'sass:map';
@use 'sass:color';
@use "../variables" as vars;
@use "../mixins" as mixins;
@use "../shadows" as shadows;
@use "../colors" as colors;

// Define JVectorMap configuration
$jvectormap: (
  "colors": (
    "background": #292929,
    "text": vars.$white-color,
    "legend-bg": black,
    "tip": colors.$gray,
    "tip-text": vars.$white-color,
  ),
  "sizes": (
    "control": (
      "size": 10px,
      "line-height": 10px,
      "padding": 3px,
      "padding-back": 6px,
    ),
    "spacing": (
      "default": 10px,
      "legend-padding": 3px,
    ),
    "legend": (
      "tick-width": 40px,
      "tick-height-h": 15px,
      "tick-size-v": 20px,
      "font-title": 14px,
      "font-text": 12px,
    ),
    "border-radius": 3px,
  ),
  "z-indices": (
    "tip": 1040,
    "back": 1000,
  ),
);

// Base container for the map
.jvectormap-container {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  touch-action: none;
}

// Tooltip styling
.jvectormap-tip {
  position: absolute;
  display: none;
  color: map.get($jvectormap, "colors", "tip-text");
  line-height: 1.5em;
  background: map.get($jvectormap, "colors", "tip");
  border: none;
  border-radius: vars.$border-radius-extreme;
  padding: 5px map.get($jvectormap, "sizes", "spacing", "default");
  z-index: map.get($jvectormap, "z-indices", "tip");
}

// Base styles for control buttons
%control-base {
  position: absolute;
  left: map.get($jvectormap, "sizes", "spacing", "default");
  border-radius: map.get($jvectormap, "sizes", "border-radius");
  background: map.get($jvectormap, "colors", "background");
  padding: map.get($jvectormap, "sizes", "control", "padding");
  color: map.get($jvectormap, "colors", "text");
  cursor: pointer;
  line-height: map.get($jvectormap, "sizes", "control", "line-height");
  text-align: center;
  box-sizing: content-box;
}

// Zoom in and out controls
.jvectormap-zoomin,
.jvectormap-zoomout {
  @extend %control-base;
  width: map.get($jvectormap, "sizes", "control", "size");
  height: map.get($jvectormap, "sizes", "control", "size");
}

.jvectormap-zoomin {
  top: map.get($jvectormap, "sizes", "spacing", "default");
}

.jvectormap-zoomout {
  top: 30px;
}

// Go back control
.jvectormap-goback {
  @extend %control-base;
  bottom: map.get($jvectormap, "sizes", "spacing", "default");
  z-index: map.get($jvectormap, "z-indices", "back");
  padding: map.get($jvectormap, "sizes", "control", "padding-back");
}

// Spinner for loading state
.jvectormap-spinner {
  position: absolute;
  inset: 0;
  background: {
    position: center;
    repeat: no-repeat;
    image: url(data:image/gif;);
  }
}

// Legend styling
.jvectormap-legend {
  background: map.get($jvectormap, "colors", "legend-bg");
  color: map.get($jvectormap, "colors", "text");
  border-radius: map.get($jvectormap, "sizes", "border-radius");

  &-title {
    font: {
      weight: bold;
      size: map.get($jvectormap, "sizes", "legend", "font-title");
    }
    text-align: center;
  }
}

// Legend container positioning
.jvectormap-legend-cnt {
  position: absolute;

  &-h {
    bottom: 0;
    right: 0;

    .jvectormap-legend {
      float: left;
      margin: 0 map.get($jvectormap, "sizes", "spacing", "default")
        map.get($jvectormap, "sizes", "spacing", "default") 0;
      padding: map.get($jvectormap, "sizes", "spacing", "legend-padding")
        map.get($jvectormap, "sizes", "spacing", "legend-padding") 1px
        map.get($jvectormap, "sizes", "spacing", "legend-padding");

      .jvectormap-legend-tick {
        float: left;
      }
    }

    .jvectormap-legend-tick {
      width: map.get($jvectormap, "sizes", "legend", "tick-width");

      &-sample {
        height: map.get($jvectormap, "sizes", "legend", "tick-height-h");
      }
    }

    .jvectormap-legend-tick-text {
      text-align: center;
    }
  }

  &-v {
    top: 0;
    right: 0;

    .jvectormap-legend {
      margin: map.get($jvectormap, "sizes", "spacing", "default")
        map.get($jvectormap, "sizes", "spacing", "default") 0 0;
      padding: map.get($jvectormap, "sizes", "spacing", "legend-padding");
    }

    .jvectormap-legend-tick-sample {
      height: map.get($jvectormap, "sizes", "legend", "tick-size-v");
      width: map.get($jvectormap, "sizes", "legend", "tick-size-v");
      display: inline-block;
      vertical-align: middle;
    }

    .jvectormap-legend-tick-text {
      display: inline-block;
      vertical-align: middle;
      line-height: map.get($jvectormap, "sizes", "legend", "tick-size-v");
      padding-left: map.get($jvectormap, "sizes", "spacing", "legend-padding");
    }
  }
}

// Tooltip text styling
.jvectormap-legend-tick-text {
  font-size: map.get($jvectormap, "sizes", "legend", "font-text");
}
