// Sass Variables
$font-family-roboto: "Roboto", "Helvetica", "Arial", sans-serif;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-size-base: 14px;
$line-height-base: 1.5em;
$color-primary: #9c27b0;
$color-primary-hover: #89229b;
$color-text-muted: #aaaaaa;
$color-text-dark: #3c4858;
$color-background: #eeeeee;
$border-radius-base: 4px;

// Global Styles
html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: normal !important;
}

body {
  background-color: $color-background;
  color: $color-text-dark;
  margin: 0;
  font-family: $font-family-roboto;
  font-weight: $font-weight-light;
  line-height: $line-height-base;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-family-roboto;
  font-weight: $font-weight-light;
  line-height: $line-height-base;
}

blockquote {
  footer {
    &:before,
    small:before {
      content: "\2014 \00A0";
    }
  }
}

h1 {
  font-size: 3em;
  line-height: 1.15em;
}

h2 {
  font-size: 2.6em;
}

h3 {
  font-size: 1.825em;
  line-height: 1.4em;
  margin: 20px 0 10px;
}

h4 {
  font-size: 1.3em;
  line-height: 1.4em;
  margin-top: 10px;
  margin-bottom: 10px;
}

h5 {
  font-size: 1.25em;
  line-height: 1.4em;
  margin-bottom: 15px;
}

h6 {
  font-size: 0.8em;
  text-transform: uppercase;
  font-weight: $font-weight-medium;
}

h4,
h5,
h6 {
  margin-top: 10px;
  margin-bottom: 10px;
}

p {
  margin: 0 0 10px;
}

blockquote p {
  font-style: italic;
}

a {
  color: $color-primary;
  text-decoration: none;

  &:hover,
  &:focus {
    color: $color-primary-hover;
    text-decoration: none;
  }
}

legend {
  border-bottom: 0;
}

* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;

  &:focus {
    outline: 0;
  }
}

a,
button {
  &:focus,
  &:active,
  &:hover,
  &::-moz-focus-inner,
  input[type="reset"]::-moz-focus-inner,
  input[type="button"]::-moz-focus-inner,
  input[type="submit"]::-moz-focus-inner,
  select::-moz-focus-inner,
  input[type="file"] > input[type="button"]::-moz-focus-inner {
    outline: 0 !important;
  }
}

legend {
  margin-bottom: 20px;
  font-size: 21px;
}

output {
  padding-top: 8px;
  font-size: $font-size-base;
  line-height: $line-height-base;
}

label {
  font-size: $font-size-base;
  line-height: $line-height-base;
  color: $color-text-muted;
  font-weight: $font-weight-regular;
}

footer {
  padding: 15px 0;

  ul {
    margin-bottom: 0;
    padding: 0;
    list-style: none;

    li {
      display: inline-block;

      a {
        color: inherit;
        padding: 15px;
        font-weight: $font-weight-medium;
        font-size: 12px;
        text-transform: uppercase;
        border-radius: $border-radius-base;
        text-decoration: none;
        position: relative;
        display: block;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}

hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #eee;
  box-sizing: content-box;
  height: 0;
}

@media (max-width: 991px) {
  body,
  html {
    position: relative;
    overflow-x: hidden;
  }

  #bodyClick {
    height: 100%;
    width: 100%;
    position: fixed;
    opacity: 0;
    top: 0;
    left: auto;
    right: 260px;
    content: "";
    z-index: 9999;
    overflow-x: hidden;
  }
}

svg {
  touch-action: none;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  small {
    font-weight: $font-weight-regular;
    line-height: 1;
    color: #777;
  }
}

h4,
h5,
h6 {
  small {
    font-size: 75%;
  }
}

code {
  padding: 2px 4px;
  font-size: 90%;
  color: #c7254e;
  background-color: #f9f2f4;
  border-radius: $border-radius-base;
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
}

img {
  vertical-align: middle;
  border: 0;
}
