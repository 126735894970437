// src/assets/scss/material-dashboard-pro-react/plugins/_plugin-react-tagsinput.scss

// Import Sass modules
@use 'sass:color';
@use 'sass:map';
@use "../variables" as vars; // Import variables with namespace 'vars'
@use "../colors" as colors; // Import colors with namespace 'colors'

// Variables
$tags-input: (
  "base": (
    "padding": (
      "container": 4px 6px,
      "tag": (
        "base": 3px 8px,
        "left": 0.8em,
        "hover": 22px,
      ),
      "input": 5px,
      "remove-icon": 0 2px,
    ),
    "margin": (
      "tag": 5px 3px 5px 0,
      "input": (
        "top": 1px,
        "bottom": 6px,
      ),
    ),
    "border-radius": 12px,
    "line-height": (
      "container": 22px,
      "tag": 1.5em,
    ),
  ),
  "font": (
    "tag": (
      "size": 0.75em,
      "weight": 500,
    ),
    "input": (
      "size": 13px,
      "weight": 400,
      "family": sans-serif,
      "color": #777,
    ),
  ),
  "colors": (
    "tag": (
      "text": vars.$white-color,
        "variants":
        (
          "default": colors.$gray,
            "primary": vars.$brand-primary,
            "info": vars.$brand-info,
            "success": vars.$brand-success,
            "warning": vars.$brand-warning,
            "danger": vars.$brand-danger,
            "rose": vars.$brand-rose,
        ),
    ),
  ),
  "transitions": (
    "tag": 300ms ease 0s,
  ),
);

// Mixins
@mixin tag-color($color) {
  background-color: $color;
  color: map.get($tags-input, "colors", "tag", "text");
}

// Base styles
.react-tagsinput {
  display: inline-block;
  padding: map.get($tags-input, "base", "padding", "container");
  max-width: 100%;
  line-height: map.get($tags-input, "base", "line-height", "container");

  .react-tagsinput-tag {
    cursor: pointer;
    margin: map.get($tags-input, "base", "margin", "tag");
    position: relative;
    padding: map.get($tags-input, "base", "padding", "tag", "base");
    padding-left: map.get($tags-input, "base", "padding", "tag", "left");
    border-radius: map.get($tags-input, "base", "border-radius");
    color: map.get($tags-input, "colors", "tag", "text");
    font: {
      weight: map.get($tags-input, "font", "tag", "weight");
      size: map.get($tags-input, "font", "tag", "size");
    }
    text-transform: uppercase;
    display: inline-block;
    line-height: map.get($tags-input, "base", "line-height", "tag");
    -webkit-transition: all map.get($tags-input, "transitions", "tag");
    -moz-transition: all map.get($tags-input, "transitions", "tag");
    -o-transition: all map.get($tags-input, "transitions", "tag");
    -ms-transition: all map.get($tags-input, "transitions", "tag");
    transition: all map.get($tags-input, "transitions", "tag");

    // Remove button
    a {
      cursor: pointer;
      position: absolute;
      top: 3px;
      right: 0;
      opacity: 0;
      background-color: transparent;
      color: map.get($tags-input, "colors", "tag", "text");

      &::before {
        font-family: "Font Awesome\\ 5 Free";
        content: "\f00d";
        padding: map.get($tags-input, "base", "padding", "remove-icon");
        font-weight: 900;
      }
    }

    // Hover state
    &:hover {
      padding-right: map.get($tags-input, "base", "padding", "tag", "hover");

      a {
        opacity: 1;
        padding-right: 4px;
        background-color: transparent;
        color: map.get($tags-input, "colors", "tag", "text");
      }
    }

    // Color variants
    @include tag-color(
      map.get($tags-input, "colors", "tag", "variants", "default")
    );

    &.primary {
      @include tag-color(
        map.get($tags-input, "colors", "tag", "variants", "primary")
      );
    }
    &.info {
      @include tag-color(
        map.get($tags-input, "colors", "tag", "variants", "info")
      );
    }
    &.success {
      @include tag-color(
        map.get($tags-input, "colors", "tag", "variants", "success")
      );
    }
    &.warning {
      @include tag-color(
        map.get($tags-input, "colors", "tag", "variants", "warning")
      );
    }
    &.danger {
      @include tag-color(
        map.get($tags-input, "colors", "tag", "variants", "danger")
      );
    }
    &.rose {
      @include tag-color(
        map.get($tags-input, "colors", "tag", "variants", "rose")
      );
    }
  }
}

// Remove styles
.react-tagsinput-remove {
  cursor: pointer;
  font-weight: bold;
}

// Input styles
.react-tagsinput-input {
  background: transparent;
  border: 0;
  color: map.get($tags-input, "font", "input", "color");
  font: {
    family: map.get($tags-input, "font", "input", "family");
    size: map.get($tags-input, "font", "input", "size");
    weight: map.get($tags-input, "font", "input", "weight");
  }
  margin: {
    bottom: map.get($tags-input, "base", "margin", "input", "bottom");
    top: map.get($tags-input, "base", "margin", "input", "top");
  }
  outline: none;
  padding: map.get($tags-input, "base", "padding", "input");
  width: 80px;
}
