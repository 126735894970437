// Import Sass modules
@use 'sass:color';
@use 'sass:map';

// Variables
$chartist: (
  "colors": (
    "white": (
      "grid": rgba(255, 255, 255, 0.2),
      "series": rgba(255, 255, 255, 0.8),
      "label": rgba(255, 255, 255, 0.7),
    ),
    "series-a": (
      "stroke": #00bcd4,
      "fill": #eebd09,
    ),
    "label": rgba(0, 0, 0, 0.4),
  ),
  "grid": (
    "stroke-width": 1px,
    "dash-array": 2px,
  ),
);

// Mixins
@mixin series-elements {
  .ct-point,
  .ct-line,
  .ct-bar,
  .ct-slice-donut {
    @content;
  }
}

@mixin flex-display {
  display: flex; // Modern browsers
}

// White color theme
.ct-chart-white-colors {
  .ct-grid {
    stroke: map.get($chartist, "colors", "white", "grid");
    stroke-width: map.get($chartist, "grid", "stroke-width");
    stroke-dasharray: map.get($chartist, "grid", "dash-array");
  }

  .ct-series-a {
    @include series-elements {
      stroke: map.get($chartist, "colors", "white", "series");
    }
  }

  .ct-label {
    color: map.get($chartist, "colors", "white", "label");

    &.ct-horizontal.ct-end {
      align-items: flex-start;
      justify-content: flex-start;
      text-align: left;
      text-anchor: start;
    }
  }
}

// Label styles
.ct-label {
  fill: map.get($chartist, "colors", "label");
  line-height: 1;
}

// Chart types label styles
.ct-chart-line,
.ct-chart-bar {
  .ct-label {
    @include flex-display;
  }
}

// Series A styles
.ct-series-a {
  @include series-elements {
    stroke: map.get($chartist, "colors", "series-a", "stroke");
  }

  .ct-slice-pie,
  .ct-slice-donut-solid,
  .ct-area {
    fill: map.get($chartist, "colors", "series-a", "fill");
  }
}
