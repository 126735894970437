.rdw-dropdown-selectedtext {
    display: flex;
    position: relative;
    height: 100%;
    align-items: center;
    padding: 0 5px;
    color: #1A405F
  }

  .rdw-editor-main {
    height: 100%;
    overflow: auto;
    box-sizing: border-box;
    padding: 0px 10px;
  }