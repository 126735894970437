// Import Sass modules
@use 'sass:color';
@use 'sass:map';

// Variables
$sweet-alert: (
  "primary-color": #9c27b0,
  "border-color": #d2d2d2,
  "text-color": #555,
  "input": (
    "height": 36px,
    "padding": 7px 0,
    "font-size": 14px,
    "line-height": 1.428571429,
    "transition-duration": 0.3s,
  ),
);

// Mixins
@mixin form-control-background($size-gradient1: 0 2px) {
  background-image: linear-gradient(
      map.get($sweet-alert, "primary-color"),
      map.get($sweet-alert, "primary-color")
    ),
    linear-gradient(
      map.get($sweet-alert, "border-color"),
      map.get($sweet-alert, "border-color")
    );
  background-size: $size-gradient1, 100% 1px;
  background-repeat: no-repeat;
  background-position: center bottom, center calc(100% - 1px);
  background-color: transparent;
}

.sweet-alert {
  margin: auto !important;

  &:focus {
    outline: none;
  }

  input.form-control {
    // Reset styles
    border: 0;
    float: none;
    box-shadow: none;
    border-radius: 0;
    width: 100%;

    // Typography
    font-weight: 400;
    font-size: map.get($sweet-alert, "input", "font-size");
    line-height: map.get($sweet-alert, "input", "line-height");
    color: map.get($sweet-alert, "text-color");

    // Dimensions
    height: map.get($sweet-alert, "input", "height");
    padding: map.get($sweet-alert, "input", "padding");

    // Background and transitions
    @include form-control-background;
    transition: background 0s ease-out;

    // Focus state
    &:focus {
      outline: none;
      box-shadow: none;
      @include form-control-background(100% 2px);
      transition-duration: map.get(
        $sweet-alert,
        "input",
        "transition-duration"
      );
    }
  }
}
