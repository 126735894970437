/* inspired by: https://css-tricks.com/responsive-data-tables/ */
.responsiveTable {
    width: 100%;
  }
  
  .responsiveTable td .tdBefore {
    display: none;
  }
  
  @media screen and (max-width: 40em) {
    /*
      Force table elements to not behave like tables anymore
      Hide table headers (but not display: none;, for accessibility)
    */
  
    .responsiveTable table,
    .responsiveTable thead,
    .responsiveTable tbody,
    .responsiveTable th,
    .responsiveTable td,
    .responsiveTable tr {
      display: block;
    }
  
    .responsiveTable thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
      /* border-bottom: 2px solid #333; */
    }
  
    .responsiveTable tbody tr {
      border: 1px solid #1A405F !important;
      border: none;
      padding: .25em;
    }
  
    .responsiveTable td.pivoted:nth-child(even) {
      /* Behave like a "row" */
      /* border: 0.5px solid #1A405F !important; */
      background: #E8EBEF !important;
      position: relative;
      padding-left: calc(55% + 10px) !important;
      text-align: left !important;
      white-space: pre-wrap;
      overflow-wrap: break-word;
    }

    .responsiveTable td.pivoted:nth-child(odd) {
      /* Behave like a "row" */
      /* border: 0.5px solid #1A405F !important; */
      background: white !important;
      position: relative;
      padding-left: calc(55% + 10px) !important;
      text-align: left !important;
      white-space: pre-wrap;
      overflow-wrap: break-word;
    }

    
  
    .responsiveTable td .tdBefore {
      /* Now like a table header */
      position: absolute;
      display: block;
  
      /* Top/left values mimic padding */
      left: 1rem;
      width: calc(60% - 20px);
      white-space: pre-wrap;
      overflow-wrap: break-word;
      text-align: left !important;
      font-weight: 600;
    }
  }