// src/assets/scss/material-dashboard-pro-react/plugins/_fileupload.scss

// Import Sass modules
@use 'sass:color';
@use 'sass:map';
@use "./variables" as vars;
@use "./colors" as colors;
@use "./mixins" as mixins;
@use "./shadows" as shadows;

// Variables
$file-upload: (
  "sizes": (
    "picture": (
      "width": 106px,
      "height": 106px,
      "border": 4px,
    ),
    "thumbnail": (
      "max-width": (
        "default": 250px,
        "circle": 100px,
      ),
      "radius": 4px,
    ),
    "font": (
      "icon": 14px,
      "input": 23px,
    ),
    "spacing": (
      "margin": (
        "bottom": (
          "default": 9px,
          "thumbnail": 10px,
        ),
        "icon": (
          "top": -6px,
          "right": 4px,
        ),
      ),
      "padding": (
        "form": (
          "top": 7px,
          "bottom": 5px,
        ),
      ),
    ),
  ),
  "colors": (
    "picture": (
      "background": #999999,
      "border": #cccccc,
      "text": #ffffff,
      "hover": colors.$pink,
    ),
    "states": (
      "warning": vars.$brand-warning,
        "error": vars.$brand-danger,
        "success": vars.$brand-success,
    ),
  ),
  "transitions": (
    "picture": all 0.2s,
  ),
);

// Mixins
@mixin state-variations($state) {
  .form-group.has-#{$state} .fileinput {
    .fileinput-preview {
      color: map.get($file-upload, "colors", "states", $state);
    }
    .thumbnail {
      border-color: map.get($file-upload, "colors", "states", $state);
    }
  }
}

// Base styles
.fileinput {
  display: inline-block;
  margin-bottom: map.get(
    $file-upload,
    "sizes",
    "spacing",
    "margin",
    "bottom",
    "default"
  );

  &.text-center {
    text-align: center;
  }

  .fa {
    font-size: map.get($file-upload, "sizes", "font", "icon");
    margin-top: map.get(
      $file-upload,
      "sizes",
      "spacing",
      "margin",
      "icon",
      "top"
    );
    position: relative;
    top: 2px;
    margin-right: map.get(
      $file-upload,
      "sizes",
      "spacing",
      "margin",
      "icon",
      "right"
    );
  }

  input[type="file"] {
    display: none;
  }

  .form-control {
    display: inline-block;
    padding-top: map.get(
      $file-upload,
      "sizes",
      "spacing",
      "padding",
      "form",
      "top"
    );
    padding-bottom: map.get(
      $file-upload,
      "sizes",
      "spacing",
      "padding",
      "form",
      "bottom"
    );
    margin-bottom: 0;
    vertical-align: middle;
    cursor: text;
  }

  .thumbnail {
    display: inline-block;
    margin-bottom: map.get(
      $file-upload,
      "sizes",
      "spacing",
      "margin",
      "bottom",
      "thumbnail"
    );
    overflow: hidden;
    text-align: center;
    vertical-align: middle;
    max-width: map.get(
      $file-upload,
      "sizes",
      "thumbnail",
      "max-width",
      "default"
    );
    @include shadows.shadow-big();

    &.img-circle {
      border-radius: 50%;
      max-width: map.get(
        $file-upload,
        "sizes",
        "thumbnail",
        "max-width",
        "circle"
      );
    }

    > img {
      max-height: 100%;
      width: 100%;
      height: auto;
      margin: {
        right: auto;
        left: auto;
      }
      display: block;
      max-width: 100%;
    }
  }

  .btn {
    vertical-align: middle;
  }
}

// State variations
@include state-variations("warning");
@include state-variations("error");
@include state-variations("success");

// Visibility states
.fileinput-exists .fileinput-new,
.fileinput-new .fileinput-exists {
  display: none;
}

// Inline styles
.fileinput-inline .fileinput-controls {
  display: inline;
}

.fileinput-filename {
  display: inline-block;
  overflow: hidden;
  vertical-align: middle;
}

.form-control .fileinput-filename {
  vertical-align: bottom;
}

// Input group styles
.fileinput.input-group {
  display: table;

  > * {
    position: relative;
    z-index: 2;
  }

  > .btn-file {
    z-index: 1;
  }
}

// New file input group styles
.fileinput-new {
  &.input-group .btn-file,
  .input-group .btn-file {
    border-radius: 0 4px 4px 0;

    &.btn-xs,
    &.btn-sm {
      border-radius: 0 3px 3px 0;
    }

    &.btn-lg {
      border-radius: 0 6px 6px 0;
    }
  }
}

// Input group addon
.input-group-addon:not(:first-child) {
  border-left: 0;
}

// Thumbnail styles
.thumbnail {
  border: 0 none;
  border-radius: map.get($file-upload, "sizes", "thumbnail", "radius");
  padding: 0;
}

// Picture container styles
.picture-container {
  position: relative;
  cursor: pointer;
  text-align: center;

  .picture {
    width: map.get($file-upload, "sizes", "picture", "width");
    height: map.get($file-upload, "sizes", "picture", "height");
    background-color: map.get($file-upload, "colors", "picture", "background");
    border: map.get($file-upload, "sizes", "picture", "border") solid
      map.get($file-upload, "colors", "picture", "border");
    color: map.get($file-upload, "colors", "picture", "text");
    border-radius: 50%;
    margin: 5px auto;
    overflow: hidden;
    transition: map.get($file-upload, "transitions", "picture");
    -webkit-transition: map.get($file-upload, "transitions", "picture");

    &:hover {
      border-color: map.get($file-upload, "colors", "picture", "hover");
    }

    input[type="file"] {
      cursor: pointer;
      display: block;
      height: 100%;
      left: 0;
      opacity: 0 !important;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  .picture-src {
    width: 100%;
  }
}
