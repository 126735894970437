// src/assets/scss/material-dashboard-pro-react/_mixins.scss
@use "sass:math";
@use "sass:color";
@use "mixins/transparency";
@use "mixins/vendor-prefixes";
@use "mixins/chartist";
@use "mixins/sidebar-color";
@use "./shadows" as shadows;
@use "./colors" as colors;
@use "./variables" as vars;

// Placeholder text
@mixin material-placeholder() {
  &::-moz-placeholder {
    @content;
  } // Firefox
  &:-ms-input-placeholder {
    @content;
  } // Internet Explorer 10+
  &::-webkit-input-placeholder {
    @content;
  } // Safari and Chrome
}

// Updated variations mixin with modern syntax
@mixin variations($component, $selector-suffix, $mdb-param-1, $color-default) {
  @include generic-variations(
    $component,
    $selector-suffix,
    $color-default,
    "variations-content",
    $mdb-param-1
  );
}

@mixin variations-content($args) {
  #{map-get($args, "material-param-1")}: map-get($args, "variation-color");
}

@mixin background-variations($component, $selector-suffix, $color-default) {
  @include generic-variations(
    $component,
    $selector-suffix,
    $color-default,
    "background-variations-content",
    null
  );
}

@mixin background-variations-content($args) {
  background-color: map-get($args, "variation-color");
  @if (map-get($args, "variation-color") == vars.$mdb-btn-background-color) {
    color: vars.$mdb-text-color-primary;
  } @else {
    color: map-get($args, "variation-color-text");
  }
}

@mixin button-variations($component, $selector-suffix, $color-default) {
  @include generic-variations(
    $component,
    $selector-suffix,
    $color-default,
    "button-variations-content",
    4%
  );
}

@mixin button-variations-content($args) {
  $variation-color: map-get($args, "variation-color");
  $mdb-param-1: map-get($args, "material-param-1");
  background-color: if(
    color.lightness($variation-color) > 50%,
    color.scale($variation-color, $lightness: -$mdb-param-1),
    color.scale($variation-color, $lightness: $mdb-param-1)
  );
}

@mixin navbar-variations($component, $selector-suffix, $color-default) {
  @include generic-variations(
    $component,
    $selector-suffix,
    $color-default,
    "navbar-variations-content",
    null
  );
}

@mixin navbar-variations-content($args) {
  $variation-color: map-get($args, "variation-color");
  $variation-color-text: map-get($args, "variation-color-text");

  background-color: $variation-color;
  color: $variation-color-text;

  .navbar-form .form-group input.form-control,
  .navbar-form input.form-control {
    @include material-placeholder {
      color: $variation-color-text;
    }
  }
  .dropdown-menu {
    border-radius: vars.$border-radius-base;
    li > a {
      &:hover,
      &:focus {
        color: colors.$white-color;
        background-color: $variation-color;
        @include shadows.shadow-big-color($variation-color);
      }
    }

    .active > a {
      &:hover,
      &:focus {
        color: $variation-color-text;
      }
      background-color: $variation-color;
      color: $variation-color-text;
      @include shadows.shadow-big-color($variation-color);
    }
  }
}

@mixin alert-variations($component, $selector-suffix, $color-default) {
  @include generic-variations(
    $component,
    $selector-suffix,
    $color-default,
    "alert-variations-content",
    null
  );
}

@mixin alert-variations-content($args) {
  $variation-color: map-get($args, "variation-color");
  $variation-color-text: map-get($args, "variation-color-text");

  @if $variation-color == vars.$brand-info {
    background-color: $variation-color;
  } @else {
    background-color: color.scale($variation-color, $lightness: 5%);
  }
  color: $variation-color-text;
  border-radius: vars.$border-radius-base;

  @include shadows.shadow-big-color($variation-color);

  a,
  .alert-link {
    color: $variation-color-text;
  }
}

@mixin call-variations-content-mixin($args) {
  $mixin-name: map-get($args, "mixin-name");
  @if $mixin-name == variations-content {
    @include variations-content($args);
  } @else if $mixin-name == background-variations-content {
    @include background-variations-content($args);
  } @else if $mixin-name == text-variations-content {
    @include text-variations-content($args);
  } @else if $mixin-name == button-variations-content {
    @include button-variations-content($args);
  } @else if $mixin-name == bg-color-variations-content {
    @include bg-color-variations-content($args);
  } @else if $mixin-name == bg-box-shadow-variations-content {
    @include bg-box-shadow-variations-content($args);
  } @else if $mixin-name == bg-img-variations-content {
    @include bg-img-variations-content($args);
  } @else if $mixin-name == navbar-variations-content {
    @include navbar-variations-content($args);
  } @else if $mixin-name == alert-variations-content {
    @include alert-variations-content($args);
  } @else {
    @error "Unknown mixin: #{$mixin-name}";
  }
}

@mixin generic-variations(
  $component,
  $selector-suffix,
  $color-default,
  $mixin-name,
  $mdb-param-1
) {
  $args: (
    "mixin-name": $mixin-name,
    "material-param-1": $mdb-param-1,
  );

  &#{$selector-suffix},
  &#{$component}-default#{$selector-suffix} {
    $args-extra: map-merge(
      $args,
      (
        "variation-color": colors.$white-color,
        "variation-color-text": vars.$gray,
      )
    );
    @include call-variations-content-mixin($args-extra);
  }
  &#{$component}-inverse#{$selector-suffix} {
    $args-inverse: map-merge(
      $args,
      (
        "variation-color": #212121,
        "variation-color-text": #fff,
      )
    );
    @include call-variations-content-mixin($args-inverse);
  }
  &#{$component}-primary#{$selector-suffix} {
    $args-primary: map-merge(
      $args,
      (
        "variation-color": vars.$brand-primary,
        "variation-color-text": vars.$mdb-text-color-light,
      )
    );
    @include call-variations-content-mixin($args-primary);
  }
  &#{$component}-success#{$selector-suffix} {
    $args-success: map-merge(
      $args,
      (
        "variation-color": vars.$brand-success,
        "variation-color-text": vars.$mdb-text-color-light,
      )
    );
    @include call-variations-content-mixin($args-success);
  }
  &#{$component}-info#{$selector-suffix} {
    $args-info: map-merge(
      $args,
      (
        "variation-color": vars.$brand-info,
        "variation-color-text": vars.$mdb-text-color-light,
      )
    );
    @include call-variations-content-mixin($args-info);
  }
  &#{$component}-warning#{$selector-suffix} {
    $args-warning: map-merge(
      $args,
      (
        "variation-color": vars.$brand-warning,
        "variation-color-text": vars.$mdb-text-color-light,
      )
    );
    @include call-variations-content-mixin($args-warning);
  }
  &#{$component}-danger#{$selector-suffix} {
    $args-danger: map-merge(
      $args,
      (
        "variation-color": vars.$brand-danger,
        "variation-color-text": vars.$mdb-text-color-light,
      )
    );
    @include call-variations-content-mixin($args-danger);
  }
  &#{$component}-rose#{$selector-suffix} {
    $args-rose: map-merge(
      $args,
      (
        "variation-color": vars.$brand-rose,
        "variation-color-text": vars.$mdb-text-color-light,
      )
    );
    @include call-variations-content-mixin($args-rose);
  }
}

@mixin transition($time, $type) {
  transition: all $time $type;
}

@mixin transform-scale($value) {
  transform: scale($value);
}

@mixin transform-translate-x($value) {
  transform: translate3d($value, 0, 0);
}

@mixin transform-translate-y($value) {
  transform: translate3d(0, $value, 0);
}

@mixin transform-origin($coordinates) {
  transform-origin: $coordinates;
}

@mixin black-filter() {
  background: rgba(0, 0, 0, 0.55);
  position: absolute;
  width: 100%;
  height: 100%;
  content: "";
  z-index: 0;
  left: 0;
  top: 0;
}

@mixin radial-gradient($extern-color, $center-color) {
  background: $extern-color;
  background: radial-gradient(
    ellipse at center,
    $center-color 0%,
    $extern-color 100%
  );
  background-size: 550% 450%;
}

@mixin tag-color($color) {
  background-color: $color;
  color: colors.$white-color;
}

@mixin create-colored-tags() {
  &.tag-primary {
    @include tag-color(vars.$brand-primary);
  }
  &.tag-info {
    @include tag-color(vars.$brand-info);
  }
  &.tag-success {
    @include tag-color(vars.$brand-success);
  }
  &.tag-warning {
    @include tag-color(vars.$brand-warning);
  }
  &.tag-danger {
    @include tag-color(vars.$brand-danger);
  }
  &.tag-rose {
    @include tag-color(vars.$brand-rose);
  }
}

@mixin rotate-180() {
  transform: rotate(180deg);
}

@mixin linear-gradient($color1, $color2) {
  background: $color1;
  background: linear-gradient(60deg, $color1, $color2);
}

@mixin set-background-color-button($color) {
  li.active > a {
    background-color: $color;
    @include shadows.shadow-big-color($color);
  }
}

@mixin btn-styles($btn-color) {
  @include shadows.shadow-big-color(
    $btn-color
  ); // Corrected mixin name and namespace

  &,
  &:hover,
  &:focus,
  &:active,
  &.active,
  &:active:focus,
  &:active:hover,
  &.active:focus,
  &.active:hover,
  .open > &.dropdown-toggle,
  .open > &.dropdown-toggle:focus,
  .open > &.dropdown-toggle:hover {
    background-color: $btn-color;
    color: vars.$white-color;
  }

  &:focus,
  &:active,
  &:hover {
    @include shadows.button-shadow-color($btn-color);
  }

  &.disabled,
  &:disabled,
  &[disabled],
  fieldset[disabled] & {
    &,
    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
      box-shadow: none;
    }
  }

  &.btn-simple {
    background-color: transparent;
    color: $btn-color;
    box-shadow: none;

    &:hover,
    &:focus,
    &:active {
      background-color: transparent;
      color: $btn-color;
    }
  }
}

@mixin social-buttons-color($color) {
  background-color: $color;
  color: #fff;
  @include shadows.shadow-2dp-color($color);

  &:focus,
  &:active,
  &:hover {
    background-color: $color;
    color: #fff;
    @include shadows.button-shadow-color($color);
  }

  &.btn-simple {
    color: $color;
    background-color: transparent;
    box-shadow: none;
  }
}

@mixin set-wizard-color($color) {
  .moving-tab {
    background-color: $color;
    @include shadows.shadow-big-color($color);
  }

  .picture {
    &:hover {
      border-color: $color;
    }
  }

  .choice {
    &:hover,
    &.active {
      .icon {
        border-color: $color;
        color: $color;
      }
    }
  }

  .form-group {
    .form-control {
      background-image: linear-gradient($color, $color),
        linear-gradient(
          vars.$mdb-input-underline-color,
          vars.$mdb-input-underline-color
        );
    }
  }

  .checkbox input[type="checkbox"]:checked + .checkbox-material {
    .check {
      background-color: $color;
    }
  }

  .radio input[type="radio"]:checked ~ .check {
    background-color: $color;
  }

  .radio input[type="radio"]:checked ~ .circle {
    border-color: $color;
  }
}

@mixin lock-page-input-color($color) {
  &.lock-page {
    .form-group {
      .form-control {
        background-image: linear-gradient($color, $color),
          linear-gradient(
            vars.$mdb-input-underline-color,
            vars.$mdb-input-underline-color
          );
      }
    }
  }
}

@mixin alert-icon-color($color) {
  i {
    color: $color;
  }
}

@mixin timeline-badge-color($color) {
  background-color: $color;
  @include shadows.shadow-big-color($color);
}
