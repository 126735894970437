// src/assets/scss/material-dashboard-pro-react/_variables.scss
@use "sass:math";
@use "sass:color";
@use "sass:map";
@use "colors" as colors;

// Define $white-color based on $white from colors.scss
$white-color: colors.$white !default;

// **Define $black-color before using it**
$black-color: #3c4858 !default;

// Typography elements
$mdb-font-family: "Roboto", "Helvetica", "Arial", sans-serif !default;
$mdb-text-color-light: colors.$white !default;
$mdb-text-color-light-hex: $white-color !default;
$mdb-text-color-primary: rgba(
  $black-color,
  0.87
) !default; // Ensure correct variable name
$mdb-text-color-primary-hex: $black-color !default;
$icon-color: rgba(0, 0, 0, 0.5) !default;

$mdb-label-color: rgba($black-color, 0.26) !default;
$mdb-label-color-toggle-focus: rgba($black-color, 0.54) !default;

// Bootstrap brand color customization
$white-transparent: rgba($white-color, 0.8);
$gray-color: #999999 !default;

$brand-default: #cecece !default;
$brand-primary: color.scale(#428bca, $lightness: -6.5%) !default;
$brand-success: #5cb85c !default;
$brand-danger: #d9534f !default;
$brand-warning: #f0ad4e !default;
$brand-info: #5bc0de !default;

$sizes-thumbnail-radius: 4px;
$brand-rose: colors.$pink !default;

$border-radius-base: 3px !default;
$border-radius-small: 2px !default;
$border-radius-large: 6px !default;
$border-radius-huge: 10px !default;
$border-radius-label: 12px !default;
$border-radius-extreme: 30px !default;

// Typography
$font-family-sans-serif: "Roboto", "Helvetica", "Arial", sans-serif !default;
$font-family-serif: "Roboto Slab", "Times New Roman", serif !default;

$headings-font-weight: 300 !default;

$font-weight-light: 300 !default;
$font-weight-default: 400 !default;
$font-weight-bold: 500 !default;
$font-weight-extra-bold: 700 !default;
$font-weight-ultra-bold: 900 !default;

$body-bg: #fff !default;

// Bootstrap variables
$bootstrap-sass-asset-helper: false !default;

// Colors
$gray-base: #000 !default;
$gray-darker: color.scale($gray-base, $lightness: 13.5%) !default;
$gray-dark: color.scale($gray-base, $lightness: 20%) !default;
$gray: color.scale($gray-base, $lightness: 33.5%) !default;
$gray-light: #999999 !default;
$gray-lighter: color.scale($gray-base, $lightness: 93.5%) !default;

// Re-defining brand colors to ensure consistency
$brand-primary: color.scale(#428bca, $lightness: -6.5%) !default;
$brand-success: #5cb85c !default;
$brand-info: #5bc0de !default;
$brand-warning: #f0ad4e !default;
$brand-danger: #d9534f !default;

// Scaffolding
$body-bg: #fff !default;
$text-color: $gray-dark !default;

$link-color: $brand-primary !default;
$link-hover-color: color.scale($link-color, $lightness: -15%) !default;
$link-hover-decoration: underline !default;

// Typography
$font-family-sans-serif: "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$font-family-serif: Georgia, "Times New Roman", Times, serif !default;
$font-family-monospace: Menlo, Monaco, Consolas, "Courier New", monospace !default;
$font-family-base: $font-family-sans-serif !default;

$font-size-base: 14px !default;
$font-size-large: math.ceil(math.div($font-size-base * 1.25, 1)) !default;
$font-size-small: 12px !default;

$font-size-h1: 3.8em !default;
$font-size-h2: 2.6em !default;
$font-size-h3: 1.825em !default;
$font-size-h4: 1.3em !default;
$font-size-h5: 1.25em !default;
$font-size-h6: 1em !default;
$font-paragraph: 16px !default;
$font-size-navbar: 16px !default;

$line-height-base: 1.428571429 !default;
$line-height-computed: math.floor(
  math.div($font-size-base * $line-height-base, 1)
) !default;

$headings-font-family: inherit !default;
$headings-font-weight: 500 !default;
$headings-line-height: 1.1 !default;
$headings-color: inherit !default;

// Iconography
$icon-font-path: if(
  $bootstrap-sass-asset-helper,
  "bootstrap/",
  "../fonts/bootstrap/"
) !default;
$icon-font-name: "glyphicons-halflings-regular" !default;
$icon-font-svg-id: "glyphicons_halflingsregular" !default;

// Components
$padding-base: 15px !default;
$margin-base: 15px !default;

$padding-base-vertical: 6px !default;
$padding-base-horizontal: 12px !default;
$padding-default-horizontal: 20px !default;

$padding-large-vertical: 10px !default;
$padding-large-horizontal: 16px !default;

$padding-small-vertical: 5px !default;
$padding-small-horizontal: 10px !default;

$padding-xs-vertical: 1px !default;
$padding-xs-horizontal: 5px !default;

$line-height-large: 1.3333333 !default;
$line-height-small: 1.5 !default;

$border-radius-base: 4px !default;
$border-radius-large: 6px !default;
$border-radius-small: 3px !default;

// Component active states
$component-active-color: #fff !default;
$component-active-bg: $brand-primary !default;

$caret-width-base: 4px !default;
$caret-width-large: 5px !default;

// Tables
$table-cell-padding: 8px !default;
$table-condensed-cell-padding: 5px !default;

$table-bg: transparent !default;
$table-bg-accent: #f9f9f9 !default;
$table-bg-hover: #f5f5f5 !default;
$table-bg-active: $table-bg-hover !default;

$table-border-color: #ddd !default;

// Buttons
$btn-font-weight: normal !default;

$btn-default-color: #333 !default;
$btn-default-bg: #fff !default;
$btn-default-border: #ccc !default;

$btn-primary-color: #fff !default;
$btn-primary-bg: $brand-primary !default;
$btn-primary-border: color.scale($btn-primary-bg, $lightness: -5%) !default;

$btn-success-color: #fff !default;
$btn-success-bg: $brand-success !default;
$btn-success-border: color.scale($btn-success-bg, $lightness: -5%) !default;

$btn-info-color: #fff !default;
$btn-info-bg: $brand-info !default;
$btn-info-border: color.scale($btn-info-bg, $lightness: -5%) !default;

$btn-warning-color: #fff !default;
$btn-warning-bg: $brand-warning !default;
$btn-warning-border: color.scale($btn-warning-bg, $lightness: -5%) !default;

$btn-danger-color: #fff !default;
$btn-danger-bg: $brand-danger !default;
$btn-danger-border: color.scale($btn-danger-bg, $lightness: -5%) !default;

$btn-link-disabled-color: $gray-light !default;

$btn-border-radius-base: $border-radius-base !default;
$btn-border-radius-large: $border-radius-large !default;
$btn-border-radius-small: $border-radius-small !default;

// Forms
$input-bg: #fff !default;
$input-bg-disabled: $gray-lighter !default;

$input-color: $gray !default;
$input-border: #ccc !default;

$input-border-radius: $border-radius-base !default;
$input-border-radius-large: $border-radius-large !default;
$input-border-radius-small: $border-radius-small !default;

$input-border-focus: #66afe9 !default;
$input-color-placeholder: #999 !default;

$input-height-base: (
  $line-height-computed + ($padding-base-vertical * 2) + 2
) !default;
$input-height-large: (
  math.ceil($font-size-large * $line-height-large) +
    ($padding-large-vertical * 2) + 2
) !default;
$input-height-small: (
  math.floor($font-size-small * $line-height-small) +
    ($padding-small-vertical * 2) + 2
) !default;

$form-group-margin-bottom: 15px !default;

$legend-color: $gray-dark !default;
$legend-border-color: #e5e5e5 !default;

$input-group-addon-bg: $gray-lighter !default;
$input-group-addon-border-color: $input-border !default;

$cursor-disabled: not-allowed !default;

// Dropdowns
$dropdown-bg: #fff !default;
$dropdown-border: rgba(0, 0, 0, 0.15) !default;
$dropdown-fallback-border: #ccc !default;
$dropdown-divider-bg: #e5e5e5 !default;

$dropdown-link-color: $gray-dark !default;
$dropdown-link-hover-color: color.scale($gray-dark, $lightness: -5%) !default;
$dropdown-link-hover-bg: #f5f5f5 !default;

$dropdown-link-active-color: $component-active-color !default;
$dropdown-link-active-bg: $component-active-bg !default;

$dropdown-link-disabled-color: $gray-light !default;

$dropdown-header-color: $gray-light !default;
$dropdown-caret-color: #000 !default;

// Z-index master list
$zindex-navbar: 1000 !default;
$zindex-dropdown: 1000 !default;
$zindex-popover: 1060 !default;
$zindex-tooltip: 1070 !default;
$zindex-navbar-fixed: 1030 !default;
$zindex-modal-background: 1040 !default;
$zindex-modal: 1050 !default;

// Media queries breakpoints
$screen-xs: 480px !default;
$screen-xs-min: $screen-xs !default;
$screen-phone: $screen-xs-min !default;

$screen-sm: 768px !default;
$screen-sm-min: $screen-sm !default;
$screen-tablet: $screen-sm-min !default;

$screen-md: 992px !default;
$screen-md-min: $screen-md !default;
$screen-desktop: $screen-md-min !default;

$screen-lg: 1200px !default;
$screen-lg-min: $screen-lg !default;
$screen-lg-desktop: $screen-lg-min !default;

$screen-xs-max: math.div($screen-sm-min - 1, 1) !default;
$screen-sm-max: math.div($screen-md-min - 1, 1) !default;
$screen-md-max: math.div($screen-lg-min - 1, 1) !default;

// Grid system
$grid-columns: 12 !default;
$grid-gutter-width: 30px !default;
$grid-float-breakpoint: $screen-sm-min !default;
$grid-float-breakpoint-max: math.div($grid-float-breakpoint - 1, 1) !default;

// Container sizes
$container-tablet: (720px + $grid-gutter-width) !default;
$container-sm: $container-tablet !default;

$container-desktop: (940px + $grid-gutter-width) !default;
$container-md: $container-desktop !default;

$container-large-desktop: (1140px + $grid-gutter-width) !default;
$container-lg: $container-large-desktop !default;

// Navbar
$navbar-height: 50px !default;
$navbar-margin-bottom: $line-height-computed !default;
$navbar-border-radius: $border-radius-base !default;
$navbar-padding-horizontal: math.div($grid-gutter-width, 2) !default;
$navbar-padding-vertical: math.div(
  ($navbar-height - $line-height-computed),
  2
) !default;
$navbar-collapse-max-height: 340px !default;

$navbar-default-color: #777 !default;
$navbar-default-bg: #f8f8f8 !default;
$navbar-default-border: color.scale(
  $navbar-default-bg,
  $lightness: -6.5%
) !default;

$navbar-default-link-color: #777 !default;
$navbar-default-link-hover-color: #333 !default;
$navbar-default-link-hover-bg: transparent !default;
$navbar-default-link-active-color: #555 !default;
$navbar-default-link-active-bg: color.scale(
  $navbar-default-bg,
  $lightness: -6.5%
) !default;
$navbar-default-link-disabled-color: #ccc !default;
$navbar-default-link-disabled-bg: transparent !default;

$navbar-default-brand-color: $navbar-default-link-color !default;
$navbar-default-brand-hover-color: color.scale(
  $navbar-default-brand-color,
  $lightness: -10%
) !default;
$navbar-default-brand-hover-bg: transparent !default;

$navbar-default-toggle-hover-bg: #ddd !default;
$navbar-default-toggle-icon-bar-bg: #888 !default;
$navbar-default-toggle-border-color: #ddd !default;

// Inverted navbar
$navbar-inverse-color: color.scale($gray-light, $lightness: 15%) !default;
$navbar-inverse-bg: #222 !default;
$navbar-inverse-border: color.scale(
  $navbar-inverse-bg,
  $lightness: -10%
) !default;

$navbar-inverse-link-color: color.scale($gray-light, $lightness: 15%) !default;
$navbar-inverse-link-hover-color: #fff !default;
$navbar-inverse-link-hover-bg: transparent !default;
$navbar-inverse-link-active-color: $navbar-inverse-link-hover-color !default;
$navbar-inverse-link-active-bg: color.scale(
  $navbar-inverse-bg,
  $lightness: -10%
) !default;
$navbar-inverse-link-disabled-color: #444 !default;
$navbar-inverse-link-disabled-bg: transparent !default;

$navbar-inverse-brand-color: $navbar-inverse-link-color !default;
$navbar-inverse-brand-hover-color: #fff !default;
$navbar-inverse-brand-hover-bg: transparent !default;

// Inverted navbar toggle
$navbar-inverse-toggle-hover-bg: #333 !default;
$navbar-inverse-toggle-icon-bar-bg: #fff !default;
$navbar-inverse-toggle-border-color: #333 !default;

// Navs
$nav-link-padding: 10px 15px !default;
$nav-link-hover-bg: $gray-lighter !default;

$nav-disabled-link-color: $gray-light !default;
$nav-disabled-link-hover-color: $gray-light !default;

// Tabs
$nav-tabs-border-color: #ddd !default;
$nav-tabs-link-hover-border-color: $gray-lighter !default;
$nav-tabs-active-link-hover-bg: $body-bg !default;
$nav-tabs-active-link-hover-color: $gray !default;
$nav-tabs-active-link-hover-border-color: #ddd !default;
$nav-tabs-justified-link-border-color: #ddd !default;
$nav-tabs-justified-active-link-border-color: $body-bg !default;

// Pills
$nav-pills-border-radius: $border-radius-base !default;
$nav-pills-active-link-hover-bg: $component-active-bg !default;
$nav-pills-active-link-hover-color: $component-active-color !default;

// Pagination
$pagination-color: $link-color !default;
$pagination-bg: #fff !default;
$pagination-border: #ddd !default;

$pagination-hover-color: $link-hover-color !default;
$pagination-hover-bg: $gray-lighter !default;
$pagination-hover-border: #ddd !default;

$pagination-active-color: #fff !default;
$pagination-active-bg: $brand-primary !default;
$pagination-active-border: $brand-primary !default;

$pagination-disabled-color: $gray-light !default;
$pagination-disabled-bg: #fff !default;
$pagination-disabled-border: #ddd !default;

// Pager
$pager-bg: $pagination-bg !default;
$pager-border: $pagination-border !default;
$pager-border-radius: 15px !default;

$pager-hover-bg: $pagination-hover-bg !default;
$pager-active-bg: $pagination-active-bg !default;
$pager-active-color: $pagination-active-color !default;
$pager-disabled-color: $pagination-disabled-color !default;

// Jumbotron
$jumbotron-padding: 30px !default;
$jumbotron-color: inherit !default;
$jumbotron-bg: $gray-lighter !default;
$jumbotron-heading-color: inherit !default;
$jumbotron-font-size: math.ceil(math.div($font-size-base * 1.5, 1)) !default;
$jumbotron-heading-font-size: math.ceil(
  math.div($font-size-base * 4.5, 1)
) !default;

// Form states and alerts
$state-success-text: #3c763d !default;
$state-success-bg: #dff0d8 !default;
$state-success-border: color.adjust(
  $state-success-bg,
  $hue: -10deg,
  $lightness: -5%
) !default;

$state-info-text: #31708f !default;
$state-info-bg: #d9edf7 !default;
$state-info-border: color.adjust(
  $state-info-bg,
  $hue: -10deg,
  $lightness: -7%
) !default;

$state-warning-text: #8a6d3b !default;
$state-warning-bg: #fcf8e3 !default;
$state-warning-border: color.adjust(
  $state-warning-bg,
  $hue: -10deg,
  $lightness: -5%
) !default;

$state-danger-text: #a94442 !default;
$state-danger-bg: #f2dede !default;
$state-danger-border: color.adjust(
  $state-danger-bg,
  $hue: -10deg,
  $lightness: -5%
) !default;

// Tooltips
$tooltip-max-width: 200px !default;
$tooltip-color: #fff !default;
$tooltip-bg: #000 !default;
$tooltip-opacity: 0.9 !default;

$tooltip-arrow-width: 5px !default;
$tooltip-arrow-color: $tooltip-bg !default;

// Popovers
$popover-bg: #fff !default;
$popover-max-width: 276px !default;
$popover-border-color: rgba(0, 0, 0, 0.2) !default;
$popover-fallback-border-color: #ccc !default;

$popover-title-bg: color.scale($popover-bg, $lightness: -3%) !default;

$popover-arrow-width: 10px !default;
$popover-arrow-color: $popover-bg !default;

$popover-arrow-outer-width: ($popover-arrow-width + 1) !default;
$popover-arrow-outer-color: rgba($popover-border-color, 0.05) !default;
$popover-arrow-outer-fallback-color: color.scale(
  $popover-fallback-border-color,
  $lightness: -20%
) !default;

// Labels
$label-default-bg: $gray-light !default;
$label-primary-bg: $brand-primary !default;
$label-success-bg: $brand-success !default;
$label-info-bg: $brand-info !default;
$label-warning-bg: $brand-warning !default;
$label-danger-bg: $brand-danger !default;

$label-color: #fff !default;
$label-link-hover-color: #fff !default;

// Modals
$modal-inner-padding: 15px !default;
$modal-title-padding: 15px !default;
$modal-title-line-height: $line-height-base !default;

$modal-content-bg: #fff !default;
$modal-content-border-color: rgba(0, 0, 0, 0.2) !default;
$modal-content-fallback-border-color: #999 !default;

$modal-backdrop-bg: #000 !default;
$modal-backdrop-opacity: 0.5 !default;
$modal-header-border-color: #e5e5e5 !default;
$modal-footer-border-color: $modal-header-border-color !default;

$modal-lg: 900px !default;
$modal-md: 600px !default;
$modal-sm: 300px !default;

// Alerts
$alert-padding: 15px !default;
$alert-border-radius: $border-radius-base !default;
$alert-link-font-weight: bold !default;

$alert-success-bg: $state-success-bg !default;
$alert-success-text: $state-success-text !default;
$alert-success-border: $state-success-border !default;

$alert-info-bg: $state-info-bg !default;
$alert-info-text: $state-info-text !default;
$alert-info-border: $state-info-border !default;

$alert-warning-bg: $state-warning-bg !default;
$alert-warning-text: $state-warning-text !default;
$alert-warning-border: $state-warning-border !default;

$alert-danger-bg: $state-danger-bg !default;
$alert-danger-text: $state-danger-text !default;
$alert-danger-border: $state-danger-border !default;

// Progress bars
$progress-bg: #f5f5f5 !default;
$progress-bar-color: #fff !default;
$progress-border-radius: $border-radius-base !default;

$progress-bar-bg: $brand-primary !default;
$progress-bar-success-bg: $brand-success !default;
$progress-bar-warning-bg: $brand-warning !default;
$progress-bar-danger-bg: $brand-danger !default;
$progress-bar-info-bg: $brand-info !default;

// List groups
$list-group-bg: #fff !default;
$list-group-border: #ddd !default;
$list-group-border-radius: $border-radius-base !default;

$list-group-hover-bg: #f5f5f5 !default;
$list-group-active-color: $component-active-color !default;
$list-group-active-bg: $component-active-bg !default;
$list-group-active-border: $list-group-active-bg !default;
$list-group-active-text-color: color.scale(
  $list-group-active-bg,
  $lightness: 40%
) !default;

$list-group-disabled-color: $gray-light !default;
$list-group-disabled-bg: $gray-lighter !default;
$list-group-disabled-text-color: $list-group-disabled-color !default;

$list-group-link-color: #555 !default;
$list-group-link-hover-color: $list-group-link-color !default;
$list-group-link-heading-color: #333 !default;

// Bootstrap Material Design specific variables
$mdb-brand-inverse: colors.$indigo !default;

// Animation
$mdb-animation-curve-fast-out-slow-in: cubic-bezier(0.4, 0, 0.2, 1) !default;
$mdb-animation-curve-linear-out-slow-in: cubic-bezier(0, 0, 0.2, 1) !default;
$mdb-animation-curve-fast-out-linear-in: cubic-bezier(0.4, 0, 1, 1) !default;
$mdb-animation-curve-default: $mdb-animation-curve-fast-out-slow-in !default;

// Layout
$mdb-text-color-light: rgba(255, 255, 255, 0.84) !default;
$mdb-text-color-primary: rgba(0, 0, 0, 0.87) !default;

// Shadows
$mdb-shadow-umbra-opacity: 0.2 !default;
$mdb-shadow-penumbra-opacity: 0.14 !default;
$mdb-shadow-ambient-opacity: 0.12 !default;

// Fancy shadow
$fancy-shadow: (
  0 13px 39px -10px rgba(0, 0, 0, 0.65),
  0 1px 25px 0px rgba(0, 0, 0, 0.15)
);

// Transitions
$general-transition-time: 300ms !default;
$slow-transition-time: 370ms !default;
$fast-transition-time: 150ms !default;

$transition-linear: linear !default;
$transition-bezier: cubic-bezier(0.34, 1.61, 0.7, 1) !default;
$transition-ease: ease 0s;
$transition-ease-in: ease-in !default;
$transition-ease-out: ease-out !default;

// Social colors
$social-facebook: #3b5998;
$social-twitter: #55acee;
$social-pinterest: #cc2127;
$social-google: #dd4b39;
$social-linkedin: #0976b4;
$social-dribbble: #ea4c89;
$social-github: #333333;
$social-youtube: #e52d27;
$social-instagram: #125688;
$social-reddit: #ff4500;
$social-tumblr: #35465c;
$social-behance: #1769ff;

// Background colors
$transparent-bg: transparent !default;
$background-light-gray: #e8e7e3 !default;
$background-lighter-gray: #f0efeb !default;
$font-background-light-gray: #9c9b99 !default;
$font-hover-background-light-gray: #5e5e5c !default;

// Layout variables
$sidebar-width: calc(100% - 260px) !default;
$sidebar-mini-width: calc(100% - 80px) !default;
$topbar-back: topbar-back !default;
$bottombar-back: bottombar-back !default;
$topbar-x: topbar-x !default;
$bottombar-x: bottombar-x !default;
$margin-bottom: 0 0 10px 0 !default;
$margin-base-vertical: 15px !default;

// Datetimepicker variables
$padding-default-vertical: 10px !default;
$medium-pale-bg: #f1eae0 !default;
$pale-bg: #f9f7f3 !default;
$font-color: #66615b !default;
