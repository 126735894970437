// Import core variables and utility functions
@use "sass:color";
@use "sass:math";
@use "../colors" as colors;
@use "../variables" as var;
@use "../mixins" as mixins;

// Mixin for slider variants
@mixin slider-variant($color) {
  .noUi-connect {
    background-color: $color;
  }

  .noUi-handle {
    border-color: $color;
  }
}

// Base target and global reset
.noUi-target,
.noUi-target * {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
  box-sizing: border-box;
}

.noUi-target {
  position: relative;
  direction: ltr;
  background-color: #c8c8c8;
  border-radius: 3px;

  .noUi-handle {
    border: 1px solid #333;
  }

  &.slider-primary {
    @include slider-variant(var.$brand-primary);
  }
  &.slider-info {
    @include slider-variant(var.$brand-info);
  }
  &.slider-success {
    @include slider-variant(var.$brand-success);
  }
  &.slider-warning {
    @include slider-variant(var.$brand-warning);
  }
  &.slider-danger {
    @include slider-variant(var.$brand-danger);
  }
  &.slider-rose {
    @include slider-variant(var.$brand-rose);
  }
}

.noUi-base,
.noUi-connects {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}

.noUi-connects {
  overflow: hidden;
  z-index: 0;
}

.noUi-connect,
.noUi-origin {
  will-change: transform;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  transform-origin: 0 0;
  transform-style: flat;
}

.noUi-connect {
  height: 100%;
  width: 100%;
}

.noUi-origin {
  height: 10%;
  width: 10%;
}

html:not([dir="rtl"]) .noUi-horizontal .noUi-origin {
  left: auto;
  right: 0;
}

.noUi-vertical .noUi-origin {
  width: 0;
}

.noUi-horizontal .noUi-origin {
  height: 0;
}

.noUi-handle {
  backface-visibility: hidden;
  position: absolute;

  .noUi-touch-area {
    height: 100%;
    width: 100%;
  }
}

.noUi-state-tap .noUi-connect,
.noUi-state-tap .noUi-origin {
  transition: transform 0.3s;
}

.noUi-state-drag * {
  cursor: inherit !important;
}

.noUi-horizontal {
  height: 2px;

  .noUi-handle {
    box-sizing: border-box;
    width: 14px;
    height: 14px;
    left: -10px;
    top: -6px;
    cursor: pointer;
    border-radius: 100%;
    transition: all 0.2s ease-out;
    border: 1px solid var.$brand-primary; // Now correctly uses 'var' namespace
    background: #fff;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  }
}

.noUi-vertical {
  width: 18px;

  .noUi-handle {
    width: 15px;
    height: 15px;
    left: 0;
    top: -7px;
  }
}

// Tooltip styling
.noUi-tooltip {
  display: block;
  position: absolute;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  background: #fff;
  color: #000;
  padding: 5px;
  text-align: center;
  white-space: nowrap;

  &.noUi-horizontal {
    transform: translate(-50%, 0);
    left: 50%;
    bottom: 120%;
  }

  &.noUi-vertical {
    transform: translate(0, -50%);
    top: 50%;
    right: 120%;
  }
}

// Pip and marker styles
.noUi-pips {
  position: absolute;
  color: #999;

  .noUi-value {
    position: absolute;
    text-align: center;

    &-sub {
      color: #ccc;
      font-size: 10px;
    }
  }

  .noUi-marker {
    position: absolute;
    background: #ccc;

    &-sub {
      background: #aaa;
    }
    &-large {
      background: #aaa;
    }
  }

  &.noUi-pips-horizontal {
    padding: 10px 0;
    height: 80px;
    top: 100%;
    left: 0;
    width: 100%;

    .noUi-marker {
      margin-left: -1px;
      width: 2px;
      height: 5px;

      &-sub {
        height: 10px;
      }
      &-large {
        height: 15px;
      }
    }
  }

  &.noUi-pips-vertical {
    padding: 0 10px;
    height: 100%;
    top: 0;
    left: 100%;

    .noUi-marker {
      width: 5px;
      height: 2px;
      margin-top: -1px;

      &-sub {
        width: 10px;
      }
      &-large {
        width: 15px;
      }
    }
  }
}

// Disabled state handling
[disabled] {
  .noUi-connect {
    background: #b8b8b8;
  }

  .noUi-target,
  .noUi-handle {
    cursor: not-allowed;
  }
}
